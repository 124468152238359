import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Agent, AgentCategory, Maybe } from '@/types'
import { ReactRenderer } from '@tiptap/react'
import { map, toPairs } from 'ramda'
import { ReactNode } from 'react'
import tippy, { Instance } from 'tippy.js'
import { match } from 'ts-pattern'

const agentSupportedMap: Record<Agent, boolean> = {
  [Agent.CrawlerAgent]: true,
  [Agent.FinancialDataAgent]: true,
  [Agent.SECFilingsAgent]: true,
  [Agent.StockDataAgent]: true,
  [Agent.TranscriptsAgent]: true,
  [Agent.CompaniesAgent]: true,
  [Agent.FundingAgent]: true,
  [Agent.DealsAgent]: true,
  [Agent.InvestorsAgent]: true,
  [Agent.DeepResearchAgent]: true,
  [Agent.DebtsAgent]: true,
  [Agent.PrivateMarketAgent]: false
}

const agentCategoryMap: Record<Agent, AgentCategory> = {
  [Agent.CrawlerAgent]: AgentCategory.WebData,
  [Agent.FinancialDataAgent]: AgentCategory.PublicMarketData,
  [Agent.SECFilingsAgent]: AgentCategory.PublicMarketData,
  [Agent.StockDataAgent]: AgentCategory.PublicMarketData,
  [Agent.TranscriptsAgent]: AgentCategory.PublicMarketData,
  [Agent.CompaniesAgent]: AgentCategory.PrivateMarketData,
  [Agent.FundingAgent]: AgentCategory.PrivateMarketData,
  [Agent.DealsAgent]: AgentCategory.PrivateMarketData,
  [Agent.InvestorsAgent]: AgentCategory.PrivateMarketData,
  [Agent.DeepResearchAgent]: AgentCategory.WebData,
  [Agent.PrivateMarketAgent]: AgentCategory.PrivateMarketData,
  [Agent.DebtsAgent]: AgentCategory.PrivateMarketData
}

const categoryOrder: Record<AgentCategory, number> = {
  [AgentCategory.PublicMarketData]: 0,
  [AgentCategory.PrivateMarketData]: 1,
  [AgentCategory.WebData]: 2
}

export const allowedAgentList = Object.values(Agent).filter((item) => agentSupportedMap[item])

export const allowedAgentListCategorized: [AgentCategory, Agent[]][] = toPairs(
  allowedAgentList.reduce((acc, item) => {
    const category = agentCategoryMap[item]
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push(item)
    return acc
  }, {} as Record<AgentCategory, Agent[]>)
).sort(([catA], [catB]) => categoryOrder[catA] - categoryOrder[catB])

export function getAgentDescription(agent: Agent): ReactNode {
  return (
    match<Agent, Maybe<ReactNode>>(agent)
      // public market data
      .with(Agent.SECFilingsAgent, () => 'Performs rapid analysis of SEC filings')
      .with(Agent.TranscriptsAgent, () => 'Performs comprehensive analysis of earnings transcripts')
      .with(Agent.StockDataAgent, () => 'Analyzes historical or current stock data of any public stock')
      .with(
        Agent.FinancialDataAgent,
        () => 'Retrieves and analyzes historical or current financial data of any public stock'
      )

      // private market data
      .with(Agent.CompaniesAgent, () => 'Research any facts about private companies')
      .with(Agent.FundingAgent, () => 'Get funding history and deals')
      .with(Agent.DealsAgent, () => 'Retrieves and analyzes M&A and IPO deals')
      .with(Agent.InvestorsAgent, () => 'Research any facts about investors')
      .with(Agent.DebtsAgent, () => 'Retrieve data on private debts and lenders')

      // web data
      .with(Agent.CrawlerAgent, () => 'Extracts data from any public website when given a URL')
      .with(Agent.DeepResearchAgent, () => 'Performs comprehensive search and reporting of any topics')

      // not supported
      .with(Agent.PrivateMarketAgent, () => undefined)

      .exhaustive()
  )
}

export function createAgentSuggestion() {
  return {
    char: '@',
    items: ({ query }: any) => {
      const filterAgent = (item: Agent) => item.toLowerCase().startsWith(query.toLowerCase())
      return allowedAgentListCategorized
        .filter(([_, agents]) => agents.some(filterAgent))
        .map(([category, agents]) => [category, agents.filter(filterAgent).sort()])
    },

    render: () => {
      let reactRenderer: ReactRenderer
      let popup: Instance

      return {
        onStart: (props: any) => {
          if (!props.clientRect) {
            return
          }

          reactRenderer = new ReactRenderer(
            (mentionListProps: { items: [AgentCategory, Agent[]][]; range: any }) => {
              const { items, range } = mentionListProps

              function handleItemClick(item: string) {
                props.editor.commands.deleteRange({ ...range, to: range.to - 1 })
                return props.command({ id: item })
              }

              return (
                <Card>
                  <CardContent className="flex flex-col p-0 overflow-y-auto max-h-[300px] py-4">
                    {items.map(([category, agents], index) => {
                      return (
                        <div key={category} className="mb-2">
                          {index !== 0 && <div className="h-px bg-border mb-2" />}
                          <p className="text-sm font-semibold px-4">{category}</p>
                          {map((item) => {
                            return (
                              <Button
                                key={item}
                                variant="ghost"
                                className="flex flex-col gap-1 w-full rounded-none justify-start h-auto items-start"
                                onClick={() => handleItemClick(item)}
                              >
                                <p className="text-sm font-medium">@{item}</p>
                                <p className="text-xs text-muted-foreground max-w-full whitespace-normal text-left">
                                  {getAgentDescription(item)}
                                </p>
                              </Button>
                            )
                          }, agents)}
                        </div>
                      )
                    })}
                  </CardContent>
                </Card>
              )
            },
            {
              props,
              editor: props.editor
            }
          )

          popup = tippy(document.body, {
            getReferenceClientRect: props.clientRect as () => DOMRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start'
          })
        },

        onUpdate(props: any) {
          reactRenderer.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup.setProps({
            getReferenceClientRect: props.clientRect as () => DOMRect
          })
        },

        onKeyDown(props: any) {
          if (props.event.key === 'Escape') {
            popup.hide()
            return true
          }
          const elem = reactRenderer.ref as any
          return elem?.onKeyDown(props)
        },

        onExit() {
          popup.destroy()
          reactRenderer.destroy()
        }
      }
    }
  }
}
