import { AuthContext } from '@/components/auth-provider'
import { ApiResearchMessage } from '@/types'
import { append } from 'ramda'
import { useContext, useState } from 'react'
import { useMount } from './use-mount'

type UseResearchPlanLogsReturn = {
  logs: ApiResearchMessage[]
  refetch(researchPlanId?: string): Promise<void>
}

type UseResearchPlanLogsOptions = {
  researchPlanId?: string
}

export function useResearchPlanLogs({ researchPlanId }: UseResearchPlanLogsOptions = {}): UseResearchPlanLogsReturn {
  const auth = useContext(AuthContext)
  const [logs, setLogs] = useState<ApiResearchMessage[]>([])

  useMount(() => {
    fetchPreviousLogs(researchPlanId)
    subscribeToResearchLogs(researchPlanId)
  })

  async function fetchPreviousLogs(researchPlanId?: string) {
    if (!researchPlanId || !auth?.client) {
      return
    }

    try {
      const { data, error } = await auth.client
        .from('research_logs')
        .select('*')
        .eq('research_plan_id', researchPlanId)
        .order('created_at', { ascending: true })

      if (error) {
        throw error
      }

      // Append the previous research logs to the message list
      setLogs((prev) => [...data, ...prev])
    } catch (error) {
      console.error('Error fetching previous research logs:', error)
    }
  }

  function subscribeToResearchLogs(researchPlanId?: string) {
    if (!researchPlanId || !auth?.client) {
      return
    }

    try {
      auth.client
        .channel('research_logs_insert')
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'research_logs',
            filter: `research_plan_id=eq.${researchPlanId}`
          },
          (payload: any) => {
            setLogs((prev) => append(payload.new, prev))
          }
        )
        .subscribe()
    } catch (e) {
      console.error('Error subscribing to research logs:', e)
    }
  }

  function refetch(id?: string) {
    return fetchPreviousLogs(id)
  }

  return { logs, refetch }
}
