import { createProjectFetcher } from '@/api/fetcher'
import { PageLoader } from '@/components/page-loader'
import { useToast } from '@/components/ui/use-toast'
import { useEnv } from '@/hooks/use-env'
import { mapApiProjectToProject } from '@/lib/domain/project'
import { result, Result } from '@/lib/result'
import { routePath } from '@/router/route-path'
import { ApiProject, Project } from '@/types'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'

export function CreateProjectPage() {
  const env = useEnv()
  const { toast } = useToast()
  const navigate = useNavigate()

  const apiCreateProject = useSWRMutation(`${env.APP_API_BASE_URL}/api/projects`, createProjectFetcher)

  useEffect(() => {
    createNewProject()
  }, [])

  async function createNewProject() {
    try {
      const projectResult = await createProject()
      if (projectResult.ok) {
        navigateToProject(projectResult.value.id)
      }
    } catch (e) {
      toast({
        variant: 'destructive',
        description: (e as Error).message
      })
      navigate({ pathname: routePath.home })
    }
  }

  async function createProject(): Promise<Result<Project>> {
    try {
      const project: ApiProject = await apiCreateProject.trigger({})
      if (!project.id) {
        throw new Error('Failed to create a project')
      }
      return result.ok(mapApiProjectToProject(project))
    } catch (e) {
      const error = e as Error
      toast({
        variant: 'destructive',
        description: error.message
      })
      return result.failed(error)
    }
  }

  function navigateToProject(projectId: string) {
    const searchParams = new URLSearchParams()
    searchParams.set('promptBuilder', 'true')
    navigate({
      pathname: `${routePath.project}/${projectId}/chat`,
      search: searchParams.toString()
    })
  }

  return <PageLoader />
}
