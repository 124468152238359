import { deleteApiKeyFetcher, getApiKeyListFetcher } from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { ThreeDotsSpinner } from '@/components/three-dots-spinner'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Spinner } from '@/components/ui/spinner'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { ApiKeyDialog } from '@/features/settings/components/api-key-dialog'
import { useEnv } from '@/hooks/use-env'
// import { useBreakpoint } from '@/hooks/use-breakpoint'
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { format, parseISO } from 'date-fns'
import { Trash } from 'lucide-react'
import { defaultTo, isEmpty } from 'ramda'
import { useContext, useMemo, useState } from 'react'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'

type ApiKeyView = {
  id: string
  name: string
  key: string
  createdAt: string
  lastUsedAt: string
}

export function SettingsKeys() {
  // const { isMd } = useBreakpoint()

  // console.log(isMd)

  const env = useEnv()
  const auth = useContext(AuthContext)
  const fetcherKey = [env.APP_API_BASE_URL, auth?.session?.access_token, '/api/api-keys']
  const getApiKeyListApi = useSWR(fetcherKey, getApiKeyListFetcher, {
    revalidateOnFocus: false
  })

  const data: ApiKeyView[] = useMemo(() => {
    return defaultTo([], getApiKeyListApi.data)
      .map((item) =>
        item.id
          ? {
              id: item.id,
              name: item.name,
              key: item.truncated_secret,
              createdAt: format(parseISO(item.created_at), 'MMM d, yyyy'),
              lastUsedAt: item.updated_at ? format(parseISO(item.updated_at), 'MMM d, yyyy') : 'N/A'
            }
          : null
      )
      .filter((item): item is ApiKeyView => item !== null)
  }, [getApiKeyListApi.data])

  const columns: ColumnDef<ApiKeyView>[] = true
    ? [
        {
          accessorKey: 'name',
          header: 'Name'
        },
        {
          accessorKey: 'key',
          header: 'Key'
        },
        {
          accessorKey: 'createdAt',
          header: 'Created'
        },
        {
          accessorKey: 'lastUsedAt',
          header: 'Last Used'
        },
        {
          id: 'actions',
          cell: ({ row }) => {
            const [open, setOpen] = useState(false)
            const deleteApiKeyApi = useSWRMutation(fetcherKey, deleteApiKeyFetcher)

            async function handleDeleteSubmit(id: string) {
              await deleteApiKeyApi.trigger(
                {
                  id
                },
                {
                  revalidate: true
                }
              )

              setOpen(false)
            }

            return (
              <AlertDialog open={open} onOpenChange={setOpen}>
                <AlertDialogTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Trash size={14} />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>This action cannot be undone.</AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <Button
                      variant="destructive"
                      disabled={deleteApiKeyApi.isMutating}
                      onClick={() => handleDeleteSubmit(row.original.id)}
                    >
                      {deleteApiKeyApi.isMutating ? <Spinner /> : 'Delete'}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )
          }
        }
      ]
    : [
        {
          id: 'all',
          header: '',
          cell() {
            return <div>all data</div>
          }
        }
      ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // for DataTable to be used all instances of data have to have unique "id" field as an index
    getRowId(row) {
      return (row as any).id as string
    }
  })

  return (
    <Card>
      <CardHeader className="flex justify-between items-center mb-2 flex-row">
        <CardTitle className="text-lg font-medium">API keys</CardTitle>

        <ApiKeyDialog>
          <Button size="sm">Create new API key</Button>
        </ApiKeyDialog>
      </CardHeader>

      <CardContent>
        <div className="text-sm mb-4">
          Your API keys are listed below. The API key is only visible and can be copied once at creation. Save it
          securely. Do not share your API key with others, or expose it in the browser or other client-side code. In
          order to protect the security of your account, DeepSeek may also automatically disable any API key that we've
          found has leaked publicly. Usage of API keys created before April 25, 2024, was not tracked.
        </div>

        <Table className="rounded-sm">
          <TableHeader className="max-md:hidden">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  )
                })}
              </TableRow>
            ))}

            {getApiKeyListApi.isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center text-sm text-gray-500">
                  <div className="flex justify-center items-center">
                    <ThreeDotsSpinner />
                  </div>
                </TableCell>
              </TableRow>
            )}

            {isEmpty(data) && !getApiKeyListApi.isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center text-sm text-gray-500">
                  <div className="text-center text-sm text-gray-500">No API keys found. Create one to get started.</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
