import { cn } from '@/lib/utils'
import { routePath } from '@/router/route-path'
import { ApiResearchMessage, Maybe } from '@/types'
import { ChevronDown, ChevronUp, ExternalLink } from 'lucide-react'
import { isEmpty, map } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Markdown } from './markdown'
import { Button } from './ui/button'
import { ScrollArea } from './ui/scroll-area'

type ResearchLogsProps = {
  messageList: ApiResearchMessage[]
  researchPlanId: string
}

export function ResearchLogs({ messageList, researchPlanId }: ResearchLogsProps) {
  const scrollAreaRef = useRef<HTMLDivElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (!scrollAreaRef.current) {
      return
    }
    scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight
  }, [messageList])

  const [scrollEdge, setScrollEdge] = useState<Maybe<'top' | 'bottom'>>(null)

  const handleScrollCapture = (event: React.UIEvent<HTMLDivElement>) => {
    if (event.currentTarget.scrollTop === 0) {
      setScrollEdge('top')
      return
    }
    if (event.currentTarget.scrollTop + event.currentTarget.clientHeight >= event.currentTarget.scrollHeight) {
      setScrollEdge('bottom')
      return
    }
    setScrollEdge(null)
  }

  if (isEmpty(messageList)) {
    return null
  }

  return (
    <div>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center gap-1 mb-2"
      >
        {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        {isExpanded ? 'Collapse' : 'Expand'} Research Logs
      </Button>

      <div className="relative">
        {/* Top fade gradient - only show when collapsed */}
        {!isExpanded && scrollEdge !== 'top' && (
          <div
            className="absolute top-0 left-0 right-0 h-16 z-10 pointer-events-none"
            style={{
              background: 'linear-gradient(to bottom, white, transparent)'
            }}
          />
        )}

        {/* Bottom fade gradient - only show when collapsed */}
        {!isExpanded && scrollEdge !== 'bottom' && (
          <div
            className="absolute bottom-0 left-0 right-0 h-16 z-10 pointer-events-none"
            style={{
              background: 'linear-gradient(to top, white, transparent)'
            }}
          />
        )}

        <ScrollArea
          ref={scrollAreaRef}
          className={cn(
            'mb-2 border-2 ml-6 p-2 border-secondary overflow-y-auto rounded-lg',
            isExpanded ? 'max-h-none' : 'max-h-[300px]'
          )}
          onScrollCapture={handleScrollCapture}
        >
          {map((item) => {
            return (
              <div
                key={item.id + String(Date.now())}
                className="text-sm text-secondary-foreground mb-2"
                style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
              >
                <Markdown>{item.content}</Markdown>
              </div>
            )
          }, messageList)}
        </ScrollArea>
      </div>

      <div className="flex justify-end">
        <Button asChild size="sm" variant="link" className="self-end">
          <Link
            className="flex items-center gap-2"
            to={{
              pathname: `${routePath.researchPlan}/${encodeURIComponent(researchPlanId)}/logs`
            }}
            target="_blank"
          >
            View logs <ExternalLink size={16} />
          </Link>
        </Button>
      </div>
    </div>
  )
}
