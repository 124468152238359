import { createAssistantMessageWithFilesFetcher, createProjectFetcher } from '@/api/fetcher'
import { ResearchSuggestion } from '@/api/octagon-api-generated'
import { PageLayout } from '@/components/page-layout'
import { PageLoader } from '@/components/page-loader'
import { PromptEditor } from '@/components/prompt-editor'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { useToast } from '@/components/ui/use-toast'
import { useEnv } from '@/hooks/use-env'
import { getAgentColor } from '@/lib/domain/agent'
import { isEnterHotkey } from '@/lib/hotkey'
import { shuffle } from '@/lib/list'
import { routePath } from '@/router/route-path'
import { ApiProject } from '@/types'
import { captureException } from '@sentry/react'
import { filter, groupBy, isEmpty, isNotNil, prop, toPairs } from 'ramda'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation from 'swr/mutation'
import { v4 } from 'uuid'

export function HomePage() {
  const env = useEnv()
  const navigate = useNavigate()
  const toast = useToast()
  const createProjectApi = useSWRMutation(`${env.APP_API_BASE_URL}/api/projects`, createProjectFetcher)
  const apiCreateAssistantMessageWithFiles = useSWRMutation(
    '/api/project/messages_with_files',
    createAssistantMessageWithFilesFetcher
  )
  const promptSuggestionUrl = useMemo(() => `/api/projects/${v4()}/suggestions`, [])
  const apiGetPromptList = useSWRImmutable<ResearchSuggestion[]>(promptSuggestionUrl)

  type WithCategory = ResearchSuggestion & { category: string }

  // one item per category with max 3 items
  const suggestionListView = useMemo(() => {
    const suggestionList = (apiGetPromptList.data ?? []).filter((item): item is WithCategory => isNotNil(item.category))
    const suggestionListRandomized = shuffle(suggestionList)
    return (
      toPairs(groupBy(prop('category'), suggestionListRandomized))
        .flatMap(([_, items]) => {
          // random one item from each category
          const validItems = filter(isNotNil, items ?? [])
          const randomIndex = Math.floor(Math.random() * validItems.length)
          return validItems[randomIndex]
        })
        .filter(isNotNil)
        // max three suggestion tiles on UI
        // assume there are 3 or more categories from API
        .slice(0, 3)
    )
  }, [apiGetPromptList.data])

  async function handlePromptSubmit(text: string, files: File[]) {
    try {
      const project: ApiProject = await createProjectApi.trigger({})
      if (isNotNil(text) && !isEmpty(text.trim())) {
        await apiCreateAssistantMessageWithFiles.trigger({
          baseUrl: env.APP_API_BASE_URL,
          projectId: project.id,
          userMessage: text,
          files
        })
      }
      navigateToProject(project.id)
    } catch (e) {
      console.error(e)
      captureException(e)
      toast.toast({
        variant: 'destructive',
        description: 'Error submitting prompt. Please try again.'
      })
    }
  }

  function navigateToProject(projectId: string) {
    navigate({
      pathname: `${routePath.project}/${projectId}/chat`
    })
  }

  const [editorValue, setEditorValue] = useState<string>('')
  function handlePromptSuggestionClick(item: WithCategory) {
    setEditorValue(item.content)
  }

  if (
    createProjectApi.isMutating ||
    apiCreateAssistantMessageWithFiles.isMutating
  ) {
    return <PageLoader />
  }

  return (
    <PageLayout>
      <div className="flex h-full overflow-auto items-center justify-center">
        <div className="grid lg:grid-cols-3 grid-cols-1 animate-in zoom-in duration-500 w-full md:w-3/4 xl:w-1/2 gap-2">
          <div className="lg:col-span-3 col-span-1">
            <PromptEditor value={editorValue} onSubmit={handlePromptSubmit} />
          </div>
          {isEmpty(suggestionListView) &&
            apiGetPromptList.isLoading &&
            [1, 2, 3].map((item) => (
              <Card key={item} className="h-full flex flex-col transition duration-500 hover:scale-110">
                <CardContent className="flex flex-col gap-2 p-2">
                  <Skeleton className="w-3/4 h-4" />
                  <Skeleton className="w-1/2 h-4" />
                  <Skeleton className="w-full h-4" />
                </CardContent>
              </Card>
            ))}

          {suggestionListView.map((item) => {
            const agentList = item.content
              .split(' ')
              .filter((item) => item.startsWith('@'))
              .map((item) => item.slice(1))

            return (
              <Card
                key={item.title}
                className="h-full flex flex-col transition duration-500 hover:scale-110"
                role="button"
                tabIndex={0}
                onClick={() => handlePromptSuggestionClick(item)}
                onKeyDown={(e) => {
                  if (isEnterHotkey(e)) {
                    handlePromptSuggestionClick(item)
                  }
                }}
              >
                <CardHeader>
                  <CardTitle title={item.title} className="text-sm h-10 overflow-hidden">
                    {item.title}
                  </CardTitle>
                  <CardDescription title={item.content}>
                    {item.content.length > 50 ? item.content.slice(0, 50) + '...' : item.content}
                  </CardDescription>
                </CardHeader>
                <div className="flex-grow"></div>
                <CardContent className="flex flex-wrap justify-end text-muted-foreground font-semibold gap-1">
                  {agentList.map((agent) => {
                    const agentColor = getAgentColor(agent)
                    return (
                      <div
                        key={agent}
                        className="rounded text-xs p-1 text-white font-normal"
                        style={{ backgroundColor: agentColor }}
                      >
                        {agent}
                      </div>
                    )
                  })}
                </CardContent>
              </Card>
            )
          })}
        </div>
      </div>
    </PageLayout>
  )
}
