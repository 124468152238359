import { AuthContext } from '@/components/auth-provider'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { routePath } from '@/router/route-path'
import { useContext } from 'react'
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { ChangePasswordPage } from './change-password'

export function SettingsProfile() {
  const authClient = useContext(AuthContext)
  const user = authClient?.session?.user

  if (!user) {
    return <Navigate to={{ pathname: routePath.logout }} />
  }

  const navigate = useNavigate()

  function handleChangePasswordClose() {
    navigate({ pathname: routePath.settings })
  }



  return (
    <Card className="lg:w-1/2">
      <CardHeader>
        <CardTitle className="text-lg font-medium text-secondary-foreground">My profile</CardTitle>
      </CardHeader>

      <CardContent>
        <Label htmlFor="name">Name</Label>
        <Input id="name" value={user.user_metadata.full_name} readOnly />

        <Separator className="my-2 bg-transparent" />

        <Label htmlFor="email">Email</Label>
        <Input id="email" value={user.email} readOnly />

        <Separator className="my-2 bg-transparent" />

        <Label htmlFor="email">Password</Label>
        <div className="flex flex-col justify-start items-start lg:justify-between lg:flex-row lg:items-center">
          <div className="text-purple">*********</div>

          <Button variant="secondary" asChild>
            <Link to={{ pathname: 'change-password' }}>Change Password</Link>
          </Button>
        </div>

        <Routes>
          <Route path="change-password" element={<ChangePasswordPage onClose={handleChangePasswordClose} />} />
        </Routes>
      </CardContent>
    </Card>
  )
}
