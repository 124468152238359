import { createAssistantMessageFetcher, createAssistantMessageWithFilesFetcher } from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { ChatBotMessage } from '@/components/chat-bot-message'
import { ChatTabs } from '@/components/chat-tabs'
import { ChatUserInputMessage, ChatUserMessage } from '@/components/chat-user-message'
import { PageLayout } from '@/components/page-layout'
import { PromptInput } from '@/components/prompt-input'
import { SuggestionSelect } from '@/components/suggestion-select'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import { ProjectPageContext } from '@/contexts/project'
import { PromptContext } from '@/contexts/prompt'
import { PromptBuilderDialog } from '@/features/chat/components/prompt-builder-dialog'
import { useEnv } from '@/hooks/use-env'
import { useMount } from '@/hooks/use-mount'
import { useRefSize } from '@/hooks/use-ref-size'
import { toHtmlId } from '@/lib/dom'
import { mapMessageToView, mapTextToMessageView } from '@/lib/domain/message'
import { deleteFromStorage, getFromStorage } from '@/lib/storage'
import { cn } from '@/lib/utils'
import { ApiProjectMessage, Maybe, MessageView, ResearchReport, ResearchSuggestion } from '@/types'
import { captureException } from '@sentry/react'
import { RealtimeChannel } from '@supabase/supabase-js'
import { ArrowDown, Sidebar } from 'lucide-react'
import { append, defaultTo, equals, isEmpty, isNil, map, not } from 'ramda'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation from 'swr/mutation'
import { match } from 'ts-pattern'
import { ProjectReports } from './project-reports'
import { Toggle } from '@/components/ui/toggle'
import { useBreakpoint } from '@/hooks/use-breakpoint'

export function ChatPage() {
  const env = useEnv()
  const projectContext = useContext(ProjectPageContext)
  const authClient = useContext(AuthContext)
  const promptContext = useContext(PromptContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const apiGetPromptList = useSWRImmutable<ResearchSuggestion[]>(
    projectContext?.project.id ? `/api/projects/${projectContext.project.id}/suggestions` : null
  )
  const projectReportUrl = projectContext?.project.id ? `/api/projects/${projectContext.project.id}/reports` : null
  const apiGetReportsList = useSWRImmutable<ResearchReport[]>(projectReportUrl, null)

  const apiCreateAssistantMessage = useSWRMutation('apiCreateAssistantMessage', createAssistantMessageFetcher)
  const apiCreateAssistantMessageWithFiles = useSWRMutation(
    'apiCreateAssistantMessageWithFiles',
    createAssistantMessageWithFilesFetcher
  )

  const [promptTip, setPromptTip] = useState<string>()
  const [promptSelectOpen, setPromptSelectOpen] = useState(false)
  const [messageList, setMessageList] = useState<MessageView[]>(
    map(mapMessageToView, defaultTo([], projectContext?.projectMessageList))
  )
  const [bigNotes, setBigNotes] = useState(false)
  const [height, setHeight] = useState(0)
  const [scrollMode, setScrollMode] = useState<'manual' | 'bottom'>('bottom')
  const [isScrollToBottomButtonShown, setIsScrollToBottomButtonShown] = useState(false)
  const { isLg } = useBreakpoint()
  const [reportsOpen, setReportsOpen] = useState(isLg)
  const [promptBuilderOpen, setPromptBuilderOpen] = useState(searchParams.has('promptBuilder'))

  const inputContainerRef = useRef<HTMLDivElement>(null)
  const { ref: messageCardRef, height: messageCardHeight, width: messageCardWidth } = useRefSize<HTMLDivElement>()
  const lastElementRef = useRef<HTMLDivElement | null>(null)

  const user = authClient?.session?.user
  const promptSelectWidth = messageCardWidth - 64 // padding 32px
  const promptSelectHeight = messageCardHeight + 28
  const promptTipList: ResearchSuggestion[] = defaultTo([], apiGetPromptList.data)

  const fetchInitialMessageList = useCallback(async () => {
    try {
      const list = await projectContext?.refetchMessageList()
      setMessageList(() => map(mapMessageToView, defaultTo([], list)))
      return list
    } catch (e) {
      return null
    }
  }, [projectContext, setMessageList])

  const checkAndSetPromptSelectOpen = useCallback(
    (list: Maybe<ApiProjectMessage[]>) => {
      const emptyList = !isNil(list) && isEmpty(list)
      const promptBuilderOpen = searchParams.has('promptBuilder')
      if (emptyList && !promptBuilderOpen) {
        setPromptSelectOpen(true)
      }
    },
    [setPromptSelectOpen]
  )

  useMount(() => {
    if (promptContext && projectContext?.project.id) {
      setPromptTip(() => promptContext.current[projectContext?.project.id])
    }

    fetchInitialMessageList().then((list) => {
      checkAndSetPromptSelectOpen(list)
    })
  })

  useMount(() => {
    const messageId = getFromStorage<string>('scrollToResearchPlan')
    if (messageId) {
      setScrollMode(() => 'manual')
      scrollToResearchPlan(messageList)
      deleteFromStorage('scrollToResearchPlan')
    } else {
      scrollToBottom()
    }
  })

  // subscribe to input container resize to adjust message scrollables area height
  useMount(() => {
    const obs = new ResizeObserver((entries) => {
      const first = entries[0]
      setScrollContainerHeight(first.contentRect.height)
    })
    if (inputContainerRef.current) {
      obs.observe(inputContainerRef.current)
    }
    return () => {
      if (inputContainerRef.current) {
        obs.unobserve(inputContainerRef.current)
      }
    }
  })

  // subscribe to scrollable area children changes to trigger scroll to bottom
  useEffect(() => {
    const obs = new MutationObserver(([entry]) => {
      const isChildrenAddedOrRemoved = entry.removedNodes.length > 0 || entry.addedNodes.length > 0
      if (equals(entry.type, 'childList') && isChildrenAddedOrRemoved) {
        if (equals(scrollMode, 'bottom')) {
          scrollToBottom()
        }
      }
    })

    if (messageCardRef.current) {
      obs.observe(messageCardRef.current, {
        subtree: true,
        childList: true,
        attributes: false
      })
    }

    return () => {
      obs.disconnect()
    }
  }, [scrollMode])

  useMount(() => {
    const client = authClient?.client
    if (!projectContext?.project.id || !client) {
      return
    }
    const projectId = projectContext.project.id
    let reportsSubscription: RealtimeChannel | null = null
    let messagesSubscription: RealtimeChannel | null = null

    try {
      reportsSubscription = client
        .channel('research_reports_insert')
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'research_reports',
            filter: `project_id=eq.${projectId}`
          },
          () => {
            apiGetReportsList.mutate()
          }
        )
        .subscribe()

      messagesSubscription = client
        .channel('project_messages_insert')
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'project_messages',
            filter: `project_id=eq.${projectId}`
          },
          () => {
            fetchInitialMessageList()
          }
        )
        .subscribe()
    } catch (error) {
      captureException(error)
    }

    return () => {
      if (reportsSubscription) {
        reportsSubscription.unsubscribe()
      }
      if (messagesSubscription) {
        messagesSubscription.unsubscribe()
      }
    }
  })

  useMount(() => {
    const obs = new IntersectionObserver((entries) => {
      const intersection = entries[0]
      if (intersection.isIntersecting) {
        setScrollMode(() => 'bottom')
      } else {
        setScrollMode(() => 'manual')
      }
    })
    if (lastElementRef.current) {
      obs.observe(lastElementRef.current)
    }
    return () => {
      if (lastElementRef.current) {
        obs.unobserve(lastElementRef.current)
      }
    }
  })

  function scrollToBottom() {
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView()
    }
  }

  function scrollToResearchPlan(messages: MessageView[]) {
    const messageId = getFromStorage<string>('scrollToResearchPlan')
    if (messageId && !isEmpty(messages)) {
      const el = document.querySelector(`#${toHtmlId(messageId)}`)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  function handleStopResearch() {
    apiGetPromptList.mutate()
  }

  function handlePromptSelect(value: ResearchSuggestion) {
    setPromptTip(() => value.content)
    setPromptSelectOpen(() => false)
  }

  async function handlePromptSubmit(prompt: string, files: File[]) {
    const messageView = mapTextToMessageView(prompt, files)
    setMessageList(append(messageView))
    setPromptTip(() => '')
  }

  function handleNotesResizeClick() {
    setBigNotes((prev) => !prev)
  }

  function handleMessageListScroll(e: any) {
    setIsScrollToBottomButtonShown(() => e.target.scrollHeight - e.target.scrollTop > document.body.offsetHeight)
  }

  function handleReportViewClick(item: MessageView) {
    setReportsOpen(() => true)
    const id = toHtmlId(item.researchPlanId)
    const el: Maybe<Element> = document.querySelector(`#${id}`)
    if (isNil(el)) {
      return
    }
    el.scrollIntoView()
  }

  function handleProjectTitleChange(message: ApiProjectMessage) {
    if (
      message.meta_data?.new_project_title &&
      not(equals(message.meta_data?.new_project_title, projectContext?.project?.title))
    ) {
      projectContext?.refetch()
    }
  }

  function handleResearchMessageCompleted() {
    apiGetPromptList.mutate()
  }

  function setScrollContainerHeight(promptHeight: number) {
    if (messageCardRef.current) {
      const top = messageCardRef.current.getBoundingClientRect().top
      const bottomPagePadding = 20
      setHeight(() => document.body.offsetHeight - top - bottomPagePadding - promptHeight - 12)
    }
  }

  async function handleCreateBotMessage(userMessage: string, files: File[]): Promise<MessageView> {
    if (isEmpty(files)) {
      const message = await apiCreateAssistantMessage.trigger({
        baseUrl: env.APP_API_BASE_URL,
        projectId: projectContext!.project.id,
        userMessage
      })
      handleProjectTitleChange(message)
      return mapMessageToView(message)
    }

    const message = await apiCreateAssistantMessageWithFiles.trigger(
      {
        baseUrl: env.APP_API_BASE_URL,
        projectId: projectContext!.project.id,
        userMessage,
        files
      },
      {
        onError() {}
      }
    )
    handleProjectTitleChange(message)
    return mapMessageToView(message)
  }

  function handleReloadResearch(prompt: string) {
    handlePromptSubmit(prompt, [])
  }

  async function handleRetryResearch(item: MessageView) {
    if (!projectContext?.project.id || !item.researchPlanId) {
      return
    }

    const message = await apiCreateAssistantMessage.trigger({
      baseUrl: env.APP_API_BASE_URL,
      projectId: projectContext.project.id,
      researchPlanId: item.researchPlanId
    })

    const messageView = mapMessageToView(message)
    setMessageList(append(messageView))
    setScrollMode(() => 'bottom')
  }

  function handleToggleReportsClick() {
    setReportsOpen(() => !reportsOpen)
  }

  function handlePromptBuilderSubmit(value: string) {
    removePromptBuilderSearchParam()
    setPromptBuilderOpen(() => false)
    setPromptTip(value)
  }

  function handlePromptBuilderOpenChange(open: boolean) {
    setPromptBuilderOpen(() => open)
    if (open) {
      return
    }
    removePromptBuilderSearchParam()
    if (not(isEmpty(messageList))) {
      return
    }
    setPromptSelectOpen(() => true)
  }

  function removePromptBuilderSearchParam() {
    setSearchParams((prev) => {
      prev.delete('promptBuilder')
      return prev
    })
  }

  return (
    <PageLayout>
      <PromptBuilderDialog
        open={promptBuilderOpen}
        onOpenChange={handlePromptBuilderOpenChange}
        onSubmit={handlePromptBuilderSubmit}
      />

      <div className="flex justify-between mb-2">
        <ChatTabs />

        <Toggle
          variant="outline"
          data-state={reportsOpen ? 'on' : 'off'}
          className="max-lg:hidden flex gap-2 items-center"
          onClick={handleToggleReportsClick}
        >
          Reports
          <Sidebar size={16} className="rotate-180" />
        </Toggle>

        <Button variant="outline" className="lg:hidden h-8 w-20" onClick={handleToggleReportsClick}>
          {match(reportsOpen)
            .with(true, () => 'Chat')
            .with(false, () => 'Reports')
            .exhaustive()}
        </Button>
      </div>

      <div className={cn('grid grid-cols-5 gap-5 flex-grow', !reportsOpen && 'grid-cols-1')}>
        <div
          className={cn(
            'flex flex-col overflow-x-hidden relative rounded-lg col-span-5',
            bigNotes && reportsOpen ? 'lg:hidden' : 'lg:col-span-3',
            reportsOpen ? 'max-lg:hidden' : ''
          )}
        >
          <div className="flex flex-col flex-grow">
            <div className="mb-3 flex-grow bg-white relative" ref={messageCardRef}>
              <ScrollArea onScrollCapture={handleMessageListScroll} style={{ height }} className="p-5">
                {map((item) => {
                  return match(item.type)
                    .with('assistant', 'research_assistant', () => (
                      <ChatBotMessage
                        key={item.id}
                        message={item}
                        onStopResearch={handleStopResearch}
                        onReloadResearch={handleReloadResearch}
                        onRetryResearch={handleRetryResearch}
                        onReportViewClick={handleReportViewClick}
                      />
                    ))
                    .with('user', () => <ChatUserMessage key={item.id} message={item} user={user} />)
                    .with('user_input', () => (
                      <ChatUserInputMessage
                        key={item.id}
                        message={item}
                        user={user}
                        onMessageCompleted={handleResearchMessageCompleted}
                        onReloadResearch={handleReloadResearch}
                        onRetryResearch={handleRetryResearch}
                        // TODO: refactor two way message sending
                        createBotMessage={handleCreateBotMessage}
                      />
                    ))
                    .exhaustive()
                }, messageList)}

                <div ref={lastElementRef} style={{ height: 10 }} />
              </ScrollArea>

              {isScrollToBottomButtonShown && (
                <Button
                  variant="secondary"
                  className="rounded-full absolute bottom-2 right-2"
                  size="icon"
                  onClick={scrollToBottom}
                >
                  <ArrowDown size="16" />
                </Button>
              )}
            </div>

            <div className="bg-secondary" ref={inputContainerRef}>
              <SuggestionSelect
                suggestionList={promptTipList}
                popupWidth={promptSelectWidth}
                popupHeight={promptSelectHeight}
                open={promptSelectOpen}
                loading={apiGetPromptList.isLoading}
                onOpenChange={setPromptSelectOpen}
                onSelect={handlePromptSelect}
              />

              <PromptInput
                onSubmit={({ prompt, files }) => handlePromptSubmit(prompt, files)}
                overrideValue={promptTip}
                project={projectContext?.project}
              />
            </div>
          </div>
        </div>

        {reportsOpen && (
          <ProjectReports
            className={cn('col-span-5', bigNotes && reportsOpen ? 'lg:col-span-5' : 'lg:col-span-2')}
            expanded={bigNotes}
            loading={apiGetReportsList.isLoading}
            reportList={apiGetReportsList.data}
            onExpand={handleNotesResizeClick}
          />
        )}
      </div>
    </PageLayout>
  )
}
