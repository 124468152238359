import { Button } from '@/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { allowedPromptFileTypeList } from '@/features/chat/utils'
import { allowedAgentListCategorized, getAgentDescription } from '@/lib/tiptap/agent-suggestion'
import { Agent } from '@/types'
import { AtSign, Paperclip } from 'lucide-react'
import { join } from 'ramda'
import { DropdownMenuGroup, DropdownMenuLabel } from './plate-ui/dropdown-menu'
import { Fragment } from 'react'

const inputAcceptAttribute = join(',', allowedPromptFileTypeList)

type PromptEditorToolbarProps = {
  onAgentClick(agent: Agent): void
  onFileInput(e: React.FormEvent<HTMLInputElement>): void
}

export function PromptEditorToolbar({ onAgentClick, onFileInput }: PromptEditorToolbarProps) {
  return (
    <div className="flex gap-1">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <AtSign size={16} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="max-h-[300px] overflow-y-auto">
          {allowedAgentListCategorized.map(([category, agents], index) => (
            <Fragment key={category}>
              {index !== 0 && <div className="h-px bg-border mb-2" />}
              <DropdownMenuLabel>{category}</DropdownMenuLabel>
              <DropdownMenuGroup className="mb-2">
                {agents.sort().map((item) => (
                  <DropdownMenuItem
                    key={item}
                    className="flex flex-col gap-1 w-full rounded-none justify-start h-auto items-start"
                    onClick={() => onAgentClick(item)}
                  >
                    <p className="text-sm font-medium">@{item}</p>
                    <p className="text-xs text-muted-foreground max-w-full whitespace-normal text-left">
                      {getAgentDescription(item)}
                    </p>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            </Fragment>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <Button variant="ghost" size="icon">
        <label htmlFor="upload-files-input" className="cursor-pointer underline text-primary">
          <Paperclip size={16} className="-rotate-45" />
        </label>
        <input
          name="files-input"
          id="upload-files-input"
          type="file"
          hidden
          multiple
          value=""
          accept={inputAcceptAttribute}
          onInput={onFileInput}
        />
      </Button>
    </div>
  )
}
