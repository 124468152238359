import { noop } from '@/lib/function'
import { createContext } from 'react'

export const UIStateContext = createContext<UIStateContextType>({
  isSidebarCollapsed: false
})

export const UIStateFunctionsContext = createContext<UIStateFunctionsContextType>({
  toggleSidebarCollapse: noop,
  collapseSidebar: noop,
  expandSidebar: noop
})

export type UIStateContextType = {
  isSidebarCollapsed: boolean
}

export type UIStateFunctionsContextType = {
  toggleSidebarCollapse(): void
  collapseSidebar(): void
  expandSidebar(): void
}
