import { createApiKeyFetcher } from '@/api/fetcher'
import { APIKey } from '@/api/octagon-api-generated'
import { AuthContext } from '@/components/auth-provider'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Spinner } from '@/components/ui/spinner'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { useEnv } from '@/hooks/use-env'
import { zodResolver } from '@hookform/resolvers/zod'
import { Copy } from 'lucide-react'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import useSWRMutation from 'swr/mutation'
import { match } from 'ts-pattern'
import { z } from 'zod'

type ApiKeyDialogProps = {
  open?: boolean
  onOpenChange?(open: boolean): void
  children?: React.ReactNode
}

const schema = z.object({
  name: z.string().min(1, { message: 'Name is required' })
})

export type FormValue = z.infer<typeof schema>

export function ApiKeyDialog({ children }: ApiKeyDialogProps) {
  const [apiKey, setApiKey] = useState<APIKey | null>(null)
  const [mode, setMode] = useState<'create' | 'loading' | 'created'>('create')
  const env = useEnv()
  const auth = useContext(AuthContext)
  const fetcherKey = [env.APP_API_BASE_URL, auth?.session?.access_token, '/api/api-keys']
  const createApiKeyApi = useSWRMutation(fetcherKey, createApiKeyFetcher)

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: ''
    }
  })

  async function handleSubmit(values: FormValue) {
    setMode('loading')

    const newApiKey = await createApiKeyApi.trigger(
      {
        name: values.name
      },
      {
        revalidate: true
      }
    )

    setMode('created')

    setApiKey(newApiKey)
  }

  const toast = useToast()

  async function handleCopy() {
    await navigator.clipboard.writeText(apiKey?.truncated_secret ?? '')
    toast.toast({
      title: 'Copied to clipboard',
      description: 'You can now paste it in your application',
      duration: 3000
    })
  }

  return (
    <Dialog
      onOpenChange={() => {
        setMode('create')
        setApiKey(null)
        form.reset({
          name: ''
        })
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create new API key</DialogTitle>
          <DialogDescription>Create a new API key to use in your application.</DialogDescription>
        </DialogHeader>

        {match(mode)
          .with('create', () => {
            return (
              <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel className="font-medium text-xs">Name</FormLabel>
                          <FormControl>
                            <Input placeholder="Enter new API key name" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />

                  <div className="h-4" />

                  <DialogFooter>
                    <DialogClose asChild>
                      <Button variant="outline">Cancel</Button>
                    </DialogClose>
                    <Button type="submit">Create</Button>
                  </DialogFooter>
                </form>
              </Form>
            )
          })
          .with('loading', () => {
            return (
              <div className="flex justify-center">
                <Spinner />
              </div>
            )
          })
          .with('created', () => {
            return (
              <div>
                <p className="text-sm text-muted-foreground mb-4">
                  Please save your secret key in a safe place since you won't be able to view it again. Keep it secure,
                  as anyone with your API key can make requests on your behalf. If you do lose it, you'll need to
                  generate a new one.
                </p>
                <div className="mb-4">
                  <Textarea value={apiKey?.truncated_secret} className="w-full" readOnly />
                </div>

                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="secondary">Close</Button>
                  </DialogClose>
                  <Button size="sm" className="flex items-center gap-2" onClick={handleCopy}>
                    <Copy size={14} />
                    Copy
                  </Button>
                </DialogFooter>
              </div>
            )
          })
          .exhaustive()}
      </DialogContent>
    </Dialog>
  )
}
