import { Markdown } from '@/components/markdown'
import { useResearchPlanLogs } from '@/hooks/use-research-plan-logs'
import { map } from 'ramda'
import { useParams } from 'react-router-dom'

export function ResearchPlanLogsPage() {
  const { id: researchPlanId } = useParams()
  const { logs } = useResearchPlanLogs({ researchPlanId })

  return (
    <div className="p-4">
      {map((item) => {
        return (
          <div
            key={item.id}
            className="text-sm text-secondary-foreground mb-2"
            style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
          >
            <Markdown>{item.content}</Markdown>
          </div>
        )
      }, logs)}
    </div>
  )
}
