import { createContext } from 'react'

export type EnvContextValue = {
  APP_API_BASE_URL?: string
  APP_SUPABASE_AUTH_URL?: string
  APP_SUPABASE_AUTH_PUBLIC_TOKEN?: string
  APP_SENTRY_DSN?: string
  APP_URL?: string
  APP_TERMS_URL?: string
  APP_PRIVACY_POLICY_URL?: string
  APP_FAQ_URL?: string
  APP_API_DOCS_WEBSITE_URL?: string
  APP_STRIPE_PUBLISHABLE_KEY?: string
}

export const EnvContext = createContext<EnvContextValue>({})
