import LogoText from '@/assets/logo-rect.svg'
import Logo from '@/assets/logo.svg'
import { Menubar, MenubarMenu, MenubarTrigger } from '@/components/ui/menubar'
import { UIStateContext, UIStateFunctionsContext } from '@/contexts/ui-state'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useEnv } from '@/hooks/use-env'
import { cn } from '@/lib/utils'
import { routePath } from '@/router/route-path'
import { Pencil2Icon } from '@radix-ui/react-icons'
import {
  ArrowLeftFromLine,
  ArrowRightFromLine,
  BookOpenText,
  CalendarClock,
  FileCode,
  FolderOpen,
  LayoutGrid,
  LogOut,
  SettingsIcon
} from 'lucide-react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button } from './ui/button'

export function PageMenu() {
  const env = useEnv()
  const { isLg } = useBreakpoint()

  const { isSidebarCollapsed: isCollapsed } = useContext(UIStateContext)
  const { toggleSidebarCollapse } = useContext(UIStateFunctionsContext)

  function handleToggleCollapse() {
    toggleSidebarCollapse()
  }

  const menuItemClassName =
    'p-2 hover:bg-white flex flex-col lg:flex-row gap-2 !justify-start items-center w-full rounded-md whitespace-nowrap'

  return (
    <Menubar
      className={cn(
        'flex-col gap-2 h-full border-none bg-transparent overflow-x-hidden w-full p-0',
        isCollapsed && 'w-10'
      )}
    >
      <div className={cn('flex flex-col gap-2 items-end mb-10', isCollapsed && 'flex-col')}>
        {isLg && (
          <Button size="icon" variant="ghost" className="shrink-0" onClick={handleToggleCollapse}>
            {isCollapsed ? (
              <ArrowRightFromLine size={16} className="text-primary" />
            ) : (
              <ArrowLeftFromLine size={16} className="text-primary" />
            )}
          </Button>
        )}
        <div className="p-0 h-10">
          <Link to={routePath.home} className="hover:bg-white flex items-center w-full rounded-md py-0">
            {isCollapsed ? (
              <img className="p-0 shrink-0 h-10" src={Logo} />
            ) : (
              <img className="p-0 shrink-0 h-10" src={LogoText} />
            )}
          </Link>
        </div>
      </div>

      <MenubarMenu>
        <MenubarTrigger className="flex gap-2 p-0 w-full ml-0 space-x-0" style={{ marginLeft: 0 }}>
          <Button
            variant="default"
            asChild
            title="New Project"
            className={cn('w-full', !isCollapsed && 'h-auto')}
            size={isCollapsed ? 'icon' : 'default'}
          >
            <Link to={routePath.createProject} className="flex flex-col lg:flex-row gap-2 items-center">
              <Pencil2Icon className="h-4 w-4 text-white" />
              {!isCollapsed && 'New Project'}
            </Link>
          </Button>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 w-full">
          <Link to={routePath.projects} className={cn(menuItemClassName, isCollapsed && '!justify-center')}>
            <LayoutGrid className="h-4 w-4" />
            {!isCollapsed && 'Projects'}
          </Link>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 w-full">
          <Link
            to={routePath.scheduledResearchPlan}
            className={cn(menuItemClassName, isCollapsed && '!justify-center')}
          >
            <CalendarClock className="h-4 w-4" />
            {!isCollapsed && 'Scheduled'}
          </Link>
        </MenubarTrigger>
      </MenubarMenu>

      <div className="grow"></div>

      <MenubarMenu>
        <MenubarTrigger className="p-0 w-full">
          <Link to={routePath.manageSources} className={cn(menuItemClassName, isCollapsed && '!justify-center')}>
            <FolderOpen className="h-4 w-4" />
            {!isCollapsed && 'Sources'}
          </Link>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 w-full">
          <Link
            to={env.APP_FAQ_URL ?? ''}
            target="_blank"
            className={cn(menuItemClassName, isCollapsed && '!justify-center')}
          >
            <BookOpenText className="h-4 w-4" />
            {!isCollapsed && 'Help'}
          </Link>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 w-full">
          <Link
            to={env.APP_API_DOCS_WEBSITE_URL ?? ''}
            target="_blank"
            className={cn(menuItemClassName, isCollapsed && '!justify-center')}
          >
            <FileCode className="h-4 w-4" />
            {!isCollapsed && 'API Reference'}
          </Link>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 w-full">
          <Link to={routePath.settings} className={cn(menuItemClassName, isCollapsed && '!justify-center')}>
            <SettingsIcon className="h-4 w-4" />
            {!isCollapsed && 'Settings'}
          </Link>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 w-full">
          <Link to={routePath.logout} className={cn(menuItemClassName, isCollapsed && '!justify-center')}>
            <LogOut className="h-4 w-4" />
            {!isCollapsed && 'Logout'}
          </Link>
        </MenubarTrigger>
      </MenubarMenu>
    </Menubar>
  )
}
