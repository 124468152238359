import { isEmpty, map } from 'ramda'
import { Button } from './ui/button'
import { File, Trash } from 'lucide-react'
import { cn } from '@/lib/utils'

type FileItem = {
  file: File
  id: string
}

type Props = {
  className?: string
  fileList: FileItem[]
  onRemove: (item: FileItem) => void
}

export function PromptEditorAttachments({ className, fileList, onRemove }: Props) {
  if (isEmpty(fileList)) {
    return null
  }

  return (
    <div className={cn('overflow-y-auto max-h-32 pl-2', className)}>
      <div className="text-xs text-muted-foreground">Attached Files ({fileList.length})</div>
      {map(
        (item) => (
          <div key={item.id} className="flex items-center py-1">
            <Button size="icon" className="p-0 size-[16px] mr-2" variant="ghost" onClick={() => onRemove(item)}>
              <Trash size={14} className="text-red-500" />
            </Button>
            <File size={16} className="mr-2" />
            <span className="text-xs">{item.file.name}</span>
          </div>
        ),
        fileList
      )}
    </div>
  )
}
