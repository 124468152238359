import LogoText from '@/assets/logo-rect.svg'
import { routePath } from '@/router/route-path'
import { Link } from 'react-router-dom'
import { PageMenu } from './page-menu'
import { PageMenuMobile } from './page-menu-mobile'

type Props = { children: React.ReactNode }

export function PageLayout({ children }: Props) {
  return (
    <section className="grid grid-cols-[1fr] grid-rows-[auto_1fr] md:grid-cols-[min-content_auto] md:grid-rows-1 gap-2 md:gap-8 h-screen px-2 md:px-8 bg-secondary">
      {/* desktop */}
      <aside className="hidden md:flex md:flex-col gap-1 bg-secondary py-2 md:py-5">
        <PageMenu />
      </aside>

      {/* small screen */}
      <aside className="flex items-center md:hidden bg-secondary py-2">
        <PageMenuMobile />

        <div className="flex items-center gap-2 grow justify-center">
          <Link to={routePath.home}>
            <img className="md:mb-[20px] p-0 shrink-0 h-8" src={LogoText} />
          </Link>
        </div>
      </aside>

      <main className="flex flex-col md:py-5 bg-secondary">{children}</main>
    </section>
  )
}
