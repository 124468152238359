import { Maybe } from '@/types'
import { isNil, not } from 'ramda'

export function clear<T>(list: Maybe<T>[]): T[] {
  return list.filter((item): item is NonNullable<T> => not(isNil(item)))
}

export function shuffle<T>(array: T[]): T[] {
  return [...array].sort(() => Math.random() - 0.5)
}
