import { WithAuthToken, apiCall } from '@/lib/api'
import {
  ApiDataGroup,
  ApiDataSet,
  ApiProject,
  ApiProjectMessage,
  ApiSourceSelection,
  Maybe,
  Project,
  ProjectApiKey,
  ProjectListFilter,
  ResearchPlan,
  SortOrder
} from '@/types'
import { defaultTo, isNil, map } from 'ramda'
import { Fetcher } from 'swr'
import { MutationFetcher } from 'swr/mutation'
import {
  APIKey,
  BalanceResponse,
  BodyCreatePaymentIntentApiStripeCreatePaymentIntentPost,
  DynamicReport,
  DynamicSection,
  PaymentIntentResponse
} from './octagon-api-generated'

export async function createProjectFetcher(key: string, options: { arg: { authToken?: string } }): Promise<ApiProject> {
  return await apiCall({ url: key, method: 'POST', token: options.arg.authToken })
}

export type CreateFolderFetcher = MutationFetcher<
  unknown,
  string,
  {
    parent?: string
    name: string
    sourceId?: string
    baseUrl?: string
  } & WithAuthToken
>

export const createFolderFetcher: CreateFolderFetcher = async (_key, options) => {
  if (!options.arg.parent || !options.arg.baseUrl || !options.arg.sourceId) {
    throw new Error('createFolderFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('parent_folder', options.arg.parent)
  searchParams.append('title', options.arg.name)
  const query = searchParams.toString()
  return await apiCall({
    url: `${options.arg.baseUrl}/api/sources/${options.arg.sourceId}/folders?${query}`,
    method: 'POST',
    token: options.arg.authToken
  })
}

export type DeleteFolderFetcher = MutationFetcher<
  unknown,
  string,
  {
    path?: string
    sourceId?: string
    baseUrl?: string
  } & WithAuthToken
>

export const deleteFolderFetcher: DeleteFolderFetcher = async (_key, options) => {
  if (!options.arg.path || !options.arg.baseUrl || !options.arg.sourceId) {
    throw new Error('deleteFolderFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('folder_path', options.arg.path)
  const query = searchParams.toString()
  return await apiCall({
    url: `${options.arg.baseUrl}/api/sources/${options.arg.sourceId}/folders?${query}`,
    method: 'DELETE',
    token: options.arg.authToken
  })
}

export type DeleteFileFetcher = MutationFetcher<
  unknown,
  string,
  {
    path?: string
    sourceId?: string
    baseUrl?: string
  } & WithAuthToken
>

export const deleteFileFetcher: DeleteFileFetcher = async (_key, options) => {
  if (!options.arg.path || !options.arg.baseUrl || !options.arg.sourceId) {
    throw new Error('deleteFileFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('file_path', options.arg.path)
  const query = searchParams.toString()
  return await apiCall({
    url: `${options.arg.baseUrl}/api/sources/${options.arg.sourceId}/files?${query}`,
    method: 'DELETE',
    token: options.arg.authToken
  })
}

export type UpdateSelectionFetcherArg = {
  include: string[]
  exclude: string[]
  sourceId?: string
  projectId?: string
  baseUrl?: string
} & WithAuthToken

export type UpdateSelectionFetcher = MutationFetcher<ApiSourceSelection, string, UpdateSelectionFetcherArg>

export const updateSelectionFetcher: UpdateSelectionFetcher = async (_key, options) => {
  if (!options.arg.baseUrl || !options.arg.sourceId || !options.arg.projectId) {
    throw new Error('updateSelectionFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('project_id', options.arg.projectId)
  const query = searchParams.toString()
  return await apiCall({
    url: `${options.arg.baseUrl}/api/sources/${options.arg.sourceId}/selections?${query}`,
    method: 'PUT',
    token: options.arg.authToken,
    body: JSON.stringify({
      include: options.arg.include,
      exclude: options.arg.exclude
    })
  })
}

export type GetSelectionByIdFetcher = Fetcher<Record<string, ApiSourceSelection>, string>

export const getSelectionByIdFetcher: GetSelectionByIdFetcher = async (key) => {
  const options = JSON.parse(key) as {
    key: string
    baseUrl?: string
    projectId?: string
    sourceIdList?: string[]
    authToken?: string
  }
  if (!options.sourceIdList || !options.baseUrl || !options.projectId) {
    throw new Error('getSelectionByIdFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('project_id', options.projectId)
  const query = searchParams.toString()

  const response = await Promise.allSettled(
    map((item) => {
      return apiCall({
        url: `${options.baseUrl}/api/sources/${item}/selections?${query}`,
        method: 'GET',
        token: options.authToken
      }).then((r) => {
        return { id: item, selection: r }
      })
    }, options.sourceIdList)
  )
  return response.reduce((acc, item) => {
    if (item.status !== 'fulfilled') {
      return acc
    }
    return { ...acc, [item.value.id]: item.value.selection }
  }, {})
}

export type UpdateSelectionByIdFetcherArg = {
  baseUrl?: string
  projectId?: string
  sourceIdSelectionPairList: Array<[string, ApiSourceSelection]>
} & WithAuthToken

export type UpdateSelectionByIdFetcher = MutationFetcher<
  Record<string, ApiSourceSelection>,
  string,
  UpdateSelectionByIdFetcherArg
>

export const updateSelectionByIdFetcher: UpdateSelectionByIdFetcher = async (_key, options) => {
  if (!options.arg.baseUrl || !options.arg.projectId) {
    throw new Error('updateSelectionByIdFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('project_id', options.arg.projectId)
  const query = searchParams.toString()

  const response = await Promise.allSettled(
    map(([id, selection]) => {
      return apiCall({
        url: `${options.arg.baseUrl}/api/sources/${id}/selections?${query}`,
        method: 'PUT',
        token: options.arg.authToken,
        body: JSON.stringify(selection)
      }).then((r) => {
        return { id, selection: r }
      })
    }, options.arg.sourceIdSelectionPairList)
  )
  return response.reduce((acc, item) => {
    if (item.status !== 'fulfilled') {
      return acc
    }
    return { ...acc, [item.value.id]: item.value.selection }
  }, {})
}

export async function deleteProjectFetcher(
  _key: ProjectApiKey,
  options: {
    arg: {
      baseUrl: string
      projectIdList: string[]
    } & WithAuthToken
  }
): Promise<unknown> {
  return await apiCall({
    url: `${options.arg.baseUrl}/api/projects`,
    method: 'DELETE',
    token: options.arg.authToken,
    body: JSON.stringify(options.arg.projectIdList)
  })
}

export async function updateProjectFetcher(
  _key: any,
  options: {
    arg: {
      baseUrl: string
      project: Project
    } & WithAuthToken
  }
): Promise<unknown> {
  const searchParams = new URLSearchParams()
  searchParams.append('title', defaultTo('', options.arg.project.title))
  return await apiCall({
    url: `${options.arg.baseUrl}/api/projects/${options.arg.project.id}?${searchParams.toString()}`,
    method: 'PUT',
    token: options.arg.authToken
  })
}

export async function duplicateProjectFetcher(
  _key: ProjectApiKey,
  options: {
    arg: {
      baseUrl: string
      projectId: string
    } & WithAuthToken
  }
): Promise<ApiProject> {
  return await apiCall({
    url: `${options.arg.baseUrl}/api/projects/${options.arg.projectId}/duplicate`,
    method: 'POST',
    token: options.arg.authToken
  })
}

export type ProjectListFetcherArg = {
  baseUrl: string
  sortBy?: Maybe<keyof ApiProject>
  sortOrder?: Maybe<SortOrder>
  page: number
  searching?: Maybe<ProjectListFilter>
} & WithAuthToken

export async function getProjectListFetcher(key: ProjectApiKey): Promise<ApiProject[]> {
  const [baseUrl, sortBy, sortOrder, page, searching, authToken] = key
  const searchParams = new URLSearchParams()
  if (sortBy) {
    searchParams.append('sort_by', sortBy)
  }
  if (sortOrder) {
    searchParams.append('sort_order', sortOrder)
  }
  searchParams.append('page', String(page))
  if (!isNil(searching)) {
    searchParams.append('query', String(searching.search))
  }
  const url: string = `${baseUrl}/api/projects${searching ? '/search' : ''}`
  return await apiCall({ url, token: authToken, search: searchParams })
}

type StartResearchFetcherOptions = {
  arg: {
    planId: string
    baseUrl?: string
  } & WithAuthToken
}

export async function startResearchFetcher(_key: string, options: StartResearchFetcherOptions) {
  const url = `${options.arg.baseUrl}/api/research/plans/${options.arg.planId}/start`
  return apiCall({ url, method: 'POST', token: options.arg.authToken })
}

type StopResearchFetcherOptions = {
  arg: {
    planId: string
    baseUrl?: string
  } & WithAuthToken
}

export async function stopResearchFetcher(_key: string, options: StopResearchFetcherOptions) {
  const url = `${options.arg.baseUrl}/api/research/plans/${options.arg.planId}/stop`
  return apiCall({ url, method: 'POST', token: options.arg.authToken })
}

type GetResearchPlanFetcherOptions = {
  arg: {
    researchPlanId: string
    baseUrl?: string
  } & WithAuthToken
}

export async function getResearchPlanFetcher(
  _key: string,
  options: GetResearchPlanFetcherOptions
): Promise<ResearchPlan> {
  const url = `${options.arg.baseUrl}/api/research/plans/${options.arg.researchPlanId}`
  return apiCall({ url, method: 'GET', token: options.arg.authToken })
}

export type GetFileDownloadUrlFetcherArg = {
  baseUrl?: string
  path: string
} & WithAuthToken

export type GetFileDownloadUrlFetcher = MutationFetcher<string, string, GetFileDownloadUrlFetcherArg>

export const getFileDownloadUrlFetcher: GetFileDownloadUrlFetcher = async (_key, options) => {
  if (!options.arg.baseUrl || !options.arg.path) {
    throw new Error('getFileDownloadUrlFetcher: required parameters not provided')
  }

  const searchParams = new URLSearchParams()
  searchParams.append('path', options.arg.path)
  const query = searchParams.toString()

  return await apiCall({
    url: `${options.arg.baseUrl}/api/files/download?${query}`,
    method: 'GET',
    token: options.arg.authToken
  })
}

export function createVaultTokenFetcher(
  _key: string,
  options: { arg: { baseUrl?: string } & WithAuthToken }
): Promise<{ token: string }> {
  return apiCall({
    url: `${options.arg.baseUrl}/api/sources/drive/vault-token`,
    method: 'POST',
    token: options.arg.authToken
  })
}

export const getTickerListFetcher = async (key: [url: string, authToken?: string]): Promise<string[]> => {
  const [url, authToken] = key
  return await apiCall({
    url,
    method: 'GET',
    token: authToken
  })
}

export type CreateAssistantMessageFetcherOptions = {
  arg: {
    projectId: string
    researchPlanId?: string
    userMessage?: string
    baseUrl?: string
  } & WithAuthToken
}

export const createAssistantMessageFetcher = async (
  _key: string,
  options: CreateAssistantMessageFetcherOptions
): Promise<ApiProjectMessage> => {
  if (!options.arg.researchPlanId && !options.arg.userMessage) {
    throw new Error('createAssistantMessageFetcher: required parameters not provided')
  }

  // Append the user message or research plan id to the query string if they are provided
  const searchParams = new URLSearchParams()
  if (options.arg.userMessage) {
    searchParams.append('user_message', options.arg.userMessage)
  } else if (options.arg.researchPlanId) {
    searchParams.append('retry', options.arg.researchPlanId)
  }
  const query = searchParams.toString()

  return await apiCall({
    url: `${options.arg.baseUrl}/api/projects/${options.arg.projectId}/messages?${query}`,
    method: 'POST',
    token: options.arg.authToken
  })
}

export type CreateAssistantMessageWithFilesFetcherOptions = {
  arg: {
    projectId: string
    researchPlanId?: string
    userMessage?: string
    baseUrl?: string
    files?: File[]
  } & WithAuthToken
}

export const createAssistantMessageWithFilesFetcher = async (
  _key: string,
  options: CreateAssistantMessageWithFilesFetcherOptions
): Promise<ApiProjectMessage> => {
  if (!options.arg.researchPlanId && !options.arg.userMessage) {
    throw new Error('createAssistantMessageWithFilesFetcher: required parameters not provided')
  }

  const formData = new FormData()
  formData.append('user_message', options.arg.userMessage ?? '')
  if (options.arg.researchPlanId) {
    formData.append('retry', options.arg.researchPlanId)
  }
  formData.append('files', new Blob(options.arg.files ?? []))

  const headers = new Headers()
  if (options.arg.authToken) {
    headers.append('Authorization', `Bearer ${options.arg.authToken}`)
  }

  return fetch(`${options.arg.baseUrl}/api/projects/${options.arg.projectId}/messages_with_files`, {
    method: 'POST',
    body: formData,
    headers
  }).then((r) => {
    if (!r.ok) {
      throw {
        status: r.status,
        statusText: r.statusText
      }
    }
    return r.json()
  })
}

export async function createScheduledResearchPlanFetcher(
  key: string,
  options: { arg: { authToken?: string; researchPlanId: string; schedule: string; timezone?: string } }
): Promise<ApiProject> {
  return await apiCall({
    url: key,
    method: 'POST',
    token: options.arg.authToken,
    body: JSON.stringify({
      research_plan_id: options.arg.researchPlanId,
      schedule: options.arg.schedule,
      timezone: options.arg.timezone
    })
  })
}

export async function updateScheduledResearchPlanFetcher(
  key: string,
  options: { arg: { authToken?: string; schedule: string; baseUrl?: string; id: string } }
): Promise<ApiProject> {
  const search = new URLSearchParams({ schedule: options.arg.schedule })
  return await apiCall({
    url: `${options.arg.baseUrl}${key}/${options.arg.id}`,
    method: 'PUT',
    token: options.arg.authToken,
    search
  })
}

export type DeleteScheduledResearchPlanFetcher = MutationFetcher<
  unknown,
  string,
  {
    idList: string[]
    baseUrl?: string
  } & WithAuthToken
>

export const deleteScheduledResearchPlanFetcher: DeleteScheduledResearchPlanFetcher = async (_key, options) => {
  if (!options.arg.idList || !options.arg.baseUrl) {
    throw new Error('deleteScheduledResearchPlanFetcher: required parameters not provided')
  }
  return await apiCall({
    url: `${options.arg.baseUrl}/api/scheduled_research_plans`,
    method: 'DELETE',
    token: options.arg.authToken,
    body: JSON.stringify(options.arg.idList)
  })
}

export async function dataGroupsFetcher(key: [url: string, authToken?: string]): Promise<ApiDataGroup[]> {
  const [url, authToken] = key
  return await apiCall({
    url,
    method: 'GET',
    token: authToken
  })
}

export async function dataSetFetcher(key: [url: string, authToken?: string]): Promise<ApiDataSet[]> {
  const [url, authToken] = key
  return await apiCall({
    url,
    method: 'GET',
    token: authToken
  })
}

export type CreateDynamicReportFetcher = MutationFetcher<
  DynamicReport,
  string,
  { baseUrl?: string; dataSetIdList: string[] } & WithAuthToken
>

export const createDynamicReportFetcher: CreateDynamicReportFetcher = async (_key, options): Promise<DynamicReport> => {
  if (!options.arg.baseUrl) {
    throw new Error('createDynamicReportFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${options.arg.baseUrl}/api/dynamic/reports`,
    method: 'POST',
    token: options.arg.authToken,
    body: JSON.stringify({
      data_set_ids: options.arg.dataSetIdList
    })
  })
}

export async function getDynamicReportFetcher(key: Maybe<string>[]): Promise<DynamicReport> {
  const [baseUrl, _, id, authToken] = key
  return await apiCall({
    url: `${baseUrl}/api/dynamic/reports/${id}`,
    method: 'GET',
    token: authToken
  })
}

export async function getDynamicReportListFetcher(key: Maybe<string>[]): Promise<DynamicReport> {
  const [baseUrl, _, __, authToken] = key
  return await apiCall({
    url: `${baseUrl}/api/dynamic/reports`,
    method: 'GET',
    token: authToken
  })
}

export async function getDynamicSectionListFetcher(key: Maybe<string>[]): Promise<DynamicSection[]> {
  const [baseUrl, _, reportId, authToken] = key
  const url = `${baseUrl}/api/dynamic/sections?${new URLSearchParams({ dynamic_report_id: reportId! }).toString()}`
  return await apiCall({
    url: url,
    method: 'GET',
    token: authToken
  })
}

export type CreateDynamicSectionFetcher = MutationFetcher<
  DynamicSection,
  Maybe<string>[],
  { prompt: string } & WithAuthToken
>

export const createDynamicSectionFetcher: CreateDynamicSectionFetcher = async (
  key,
  options
): Promise<DynamicSection> => {
  const [baseUrl, _, reportId, authToken] = key
  if (!baseUrl) {
    throw new Error('createDynamicSectionFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/dynamic/sections`,
    method: 'POST',
    token: authToken,
    body: JSON.stringify({
      prompt: options.arg.prompt,
      dynamic_report_id: reportId
    })
  })
}

export type UpdateDynamicSectionFetcher = MutationFetcher<
  DynamicSection,
  Maybe<string>[],
  { sectionId: string; prompt: string } & WithAuthToken
>

export const updateDynamicSectionFetcher: UpdateDynamicSectionFetcher = async (
  key,
  options
): Promise<DynamicSection> => {
  const [baseUrl, _, __, authToken] = key
  if (!baseUrl) {
    throw new Error('updateDynamicSectionFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/dynamic/sections/${options.arg.sectionId}`,
    method: 'PUT',
    token: authToken,
    body: JSON.stringify({
      prompt: options.arg.prompt
    })
  })
}

export type UpdateDynamicSectionTitleFetcher = MutationFetcher<
  DynamicSection,
  Maybe<string>[],
  { sectionId: string; title: string } & WithAuthToken
>

export const updateDynamicSectionTitleFetcher: UpdateDynamicSectionTitleFetcher = async (
  key,
  options
): Promise<DynamicSection> => {
  const [baseUrl, _, __, authToken] = key
  if (!baseUrl) {
    throw new Error('updateDynamicSectionTitleFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/dynamic/sections/${options.arg.sectionId}/title`,
    method: 'PUT',
    token: authToken,
    body: JSON.stringify({
      title: options.arg.title
    })
  })
}

export type UpdateDynamicReportTitleFetcher = MutationFetcher<
  DynamicReport,
  Maybe<string>[],
  { title: string } & WithAuthToken
>

export const updateDynamicReportTitleFetcher: UpdateDynamicReportTitleFetcher = async (
  key,
  options
): Promise<DynamicReport> => {
  const [baseUrl, _, reportId, authToken] = key
  if (!baseUrl) {
    throw new Error('updateDynamicReportTitleFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/dynamic/reports/${reportId}/title`,
    method: 'PUT',
    token: authToken,
    body: JSON.stringify({
      title: options.arg.title
    })
  })
}

export type DuplicateDynamicSectionFetcher = MutationFetcher<
  DynamicSection,
  Maybe<string>[],
  { sectionId: string } & WithAuthToken
>

export const duplicateDynamicSectionFetcher: DuplicateDynamicSectionFetcher = async (
  key,
  options
): Promise<DynamicSection> => {
  const [baseUrl, _, __, authToken] = key
  if (!baseUrl) {
    throw new Error('duplicateDynamicSectionFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/dynamic/sections/${options.arg.sectionId}/duplicate`,
    method: 'POST',
    token: authToken
  })
}

export type DeleteDynamicSectionFetcher = MutationFetcher<
  DynamicSection,
  Maybe<string>[],
  { sectionId: string } & WithAuthToken
>

export const deleteDynamicSectionFetcher: DeleteDynamicSectionFetcher = async (
  key,
  options
): Promise<DynamicSection> => {
  const [baseUrl, _, __, authToken] = key
  if (!baseUrl) {
    throw new Error('deleteDynamicSectionFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/dynamic/sections/${options.arg.sectionId}`,
    method: 'DELETE',
    token: authToken
  })
}

export type SubmitDynamicSectionFetcher = MutationFetcher<
  DynamicSection,
  Maybe<string>[],
  { sectionId: string; formData: unknown } & WithAuthToken
>

export const submitDynamicSectionFetcher: SubmitDynamicSectionFetcher = async (
  key,
  options
): Promise<DynamicSection> => {
  const [baseUrl, _, __, authToken] = key
  if (!baseUrl) {
    throw new Error('SubmitDynamicSectionFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/dynamic/sections/${options.arg.sectionId}/submit`,
    method: 'POST',
    token: authToken,
    body: JSON.stringify({
      form_data: options.arg.formData
    })
  })
}

export type ReorderDynamicSectionFetcher = MutationFetcher<
  DynamicSection,
  Maybe<string>[],
  { sectionId: string; rankOrder: number } & WithAuthToken
>

export const reorderDynamicSectionFetcher: ReorderDynamicSectionFetcher = async (
  key,
  options
): Promise<DynamicSection> => {
  const [baseUrl, _, __, authToken] = key
  if (!baseUrl) {
    throw new Error('reorderDynamicSectionFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/dynamic/sections/${options.arg.sectionId}/rank-order`,
    method: 'PUT',
    token: authToken,
    body: JSON.stringify({
      rank_order: options.arg.rankOrder
    })
  })
}

export type ExportDynamicReportToPdfFetcher = MutationFetcher<{ s3_url: string }, Maybe<string>[], {} & WithAuthToken>

export const exportDynamicReportToPdfFetcher: ExportDynamicReportToPdfFetcher = async (
  key
): Promise<{ s3_url: string }> => {
  const [baseUrl, _, reportId, authToken] = key
  return await apiCall({
    url: `${baseUrl}/api/dynamic/reports/${reportId}/export-pdf`,
    method: 'POST',
    token: authToken
  })
}

export type ExportDynamicSectionToCsvFetcher = MutationFetcher<
  { s3_url: string },
  Maybe<string>[],
  { sectionId: string } & WithAuthToken
>

export const exportDynamicSectionToCsvFetcher: ExportDynamicSectionToCsvFetcher = async (
  key,
  options
): Promise<{ s3_url: string }> => {
  const [baseUrl, _, __, authToken] = key
  const {
    arg: { sectionId }
  } = options
  return await apiCall({
    url: `${baseUrl}/api/dynamic/sections/${sectionId}/export-csv`,
    method: 'POST',
    token: authToken
  })
}

export type GetApiKeyListFetcher = Fetcher<APIKey[], Maybe<string>[]>

export const getApiKeyListFetcher: GetApiKeyListFetcher = async (key): Promise<APIKey[]> => {
  const [baseUrl, authToken, _] = key
  if (!baseUrl) {
    throw new Error('getApiKeyListFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/api-keys`,
    method: 'GET',
    token: authToken
  })
}

export type CreateApiKeyFetcher = MutationFetcher<APIKey, Maybe<string>[], { name: string } & WithAuthToken>

export const createApiKeyFetcher: CreateApiKeyFetcher = async (key, options): Promise<APIKey> => {
  const [baseUrl, authToken, _] = key
  if (!baseUrl) {
    throw new Error('createApiKeyFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/api-keys`,
    method: 'POST',
    token: authToken,
    body: JSON.stringify({
      name: options.arg.name
    })
  })
}

export type DeleteApiKeyFetcher = MutationFetcher<unknown, Maybe<string>[], { id: string } & WithAuthToken>

export const deleteApiKeyFetcher: DeleteApiKeyFetcher = async (key, options): Promise<unknown> => {
  const [baseUrl, authToken, _] = key
  if (!baseUrl) {
    throw new Error('deleteApiKeyFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/api-keys/${options.arg.id}`,
    method: 'DELETE',
    token: authToken
  })
}

export type GetApiBalanceFetcher = Fetcher<BalanceResponse, Maybe<string>[]>

export const getApiBalanceFetcher: GetApiBalanceFetcher = async (key): Promise<BalanceResponse> => {
  const [baseUrl, authToken, _] = key
  if (!baseUrl) {
    throw new Error('getApiBalanceFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/balance`,
    method: 'GET',
    token: authToken
  })
}

export type CreateStripePaymentIntentFetcher = MutationFetcher<
  PaymentIntentResponse,
  Maybe<string>[],
  BodyCreatePaymentIntentApiStripeCreatePaymentIntentPost & WithAuthToken
>

export const createStripePaymentIntentFetcher: CreateStripePaymentIntentFetcher = async (
  key,
  options
): Promise<PaymentIntentResponse> => {
  const [baseUrl, authToken, _] = key
  if (!baseUrl || isNil(options.arg.amount)) {
    throw new Error('createStripePaymentIntentFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${baseUrl}/api/stripe/create-payment-intent`,
    method: 'POST',
    token: authToken,
    body: JSON.stringify({
      amount: options.arg.amount
    })
  })
}
