import { UIStateContext, UIStateFunctionsContext } from '@/contexts/ui-state'
import { F, not, T } from 'ramda'
import { useState } from 'react'

type Props = {
  children: React.ReactNode
}

export function UIStateProvider({ children }: Props) {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false)

  function toggleSidebarCollapse() {
    setIsSidebarCollapsed(not)
  }

  function collapseSidebar() {
    setIsSidebarCollapsed(T)
  }

  function expandSidebar() {
    setIsSidebarCollapsed(F)
  }

  return (
    <UIStateContext.Provider value={{ isSidebarCollapsed }}>
      <UIStateFunctionsContext.Provider value={{ toggleSidebarCollapse, collapseSidebar, expandSidebar }}>
        {children}
      </UIStateFunctionsContext.Provider>
    </UIStateContext.Provider>
  )
}
