import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import { OutputValue } from '../types'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { FormEvent } from 'react'

export const webCrawlerSchema = z.object({
  url: z.string().url({ message: 'Invalid URL' }),
  maxPages: z.string().optional(),
  allFieldsCheckbox: z.boolean().optional(),
  detailedPagesCheckbox: z.boolean().optional(),
  outputs: z.array(z.nativeEnum(OutputValue)).optional()
})

export type WebCrawlerFormValue = z.infer<typeof webCrawlerSchema>

const maxPagesOptionList: { value: string; label: string }[] = [1, 2, 10, 20, 30, 50, 100, 200].map((item) => ({
  value: item.toString(),
  label: item.toString()
}))

const webCrawlerOutputsOptionList: { value: OutputValue; label: string }[] = Object.values(OutputValue).map((item) => ({
  value: item,
  label: item
}))

type Props = {
  form: UseFormReturn<WebCrawlerFormValue>
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
}

export function PromptBuilderWebCrawlerForm({ form, onSubmit }: Props) {
  return (
    <Form key="prompt-builder-web-crawler-form" {...form}>
      <form key="prompt-builder-web-crawler-form" onSubmit={onSubmit}>
        <FormField
          control={form.control}
          name="url"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold text-xs">What is target URL? *</FormLabel>
              <FormControl>
                <Input
                  placeholder="https://example.com"
                  {...field}
                  data-testid="prompt-builder-web-crawler-url-input"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="h-5" />

        <FormField
          control={form.control}
          name="allFieldsCheckbox"
          render={({ field }) => (
            <FormItem className="flex items-center space-y-0 space-x-2">
              <Checkbox id="allFields" checked={field.value} disabled={true} onCheckedChange={field.onChange} />
              <label htmlFor="allFields" className="font-normal text-xs text-muted-foreground mt-0">
                All available fields
              </label>
            </FormItem>
          )}
        />

        <div className="h-5" />

        <Accordion type="multiple" className="">
          <AccordionItem value="1" className="border-b-0">
            <AccordionTrigger className="font-notmal text-sm hover:no-underline text-muted-foreground">
              Advanced
            </AccordionTrigger>
            <AccordionContent className="p-1">
              <FormField
                control={form.control}
                name="detailedPagesCheckbox"
                render={({ field }) => (
                  <FormItem className="flex items-center space-y-0 space-x-2">
                    <Checkbox id="detailedPages" checked={field.value} onCheckedChange={field.onChange} />
                    <label
                      htmlFor="detailedPages"
                      className="font-normal text-xs text-muted-foreground mt-0 flex gap-2 items-center"
                    >
                      <span>Include detailed pages</span>
                      <div className="px-1 py-0.5 text-xs bg-yellow-100 text-yellow-800 rounded">Beta</div>
                    </label>
                  </FormItem>
                )}
              />

              <div className="h-5" />

              <FormField
                control={form.control}
                name="maxPages"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-semibold text-xs">What is the max page?</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <SelectTrigger>
                          <SelectValue placeholder="Not selected" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Max pages</SelectLabel>
                            {maxPagesOptionList.map((item) => (
                              <SelectItem key={item.value} value={item.value}>
                                {item.label}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="h-5" />

              <FormField
                control={form.control}
                name="outputs"
                render={({ field }) => (
                  <FormItem className="">
                    <FormLabel className="font-semibold text-xs">Outputs</FormLabel>
                    {webCrawlerOutputsOptionList.map((item) => (
                      <div key={item.value} className="flex items-center space-x-2 mb-2">
                        <Checkbox
                          id={item.value}
                          checked={(field.value as string[])?.includes(item.value)}
                          disabled={[OutputValue.JSON, OutputValue.CSV].includes(item.value)}
                          onCheckedChange={(e) => {
                            const value = field.value ?? []
                            if (e) {
                              field.onChange([...value, item.value])
                            } else {
                              field.onChange((value as string[]).filter((value) => value !== item.value))
                            }
                          }}
                        />
                        <label htmlFor={item.value} className="font-normal text-xs text-muted-foreground mt-0">
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </FormItem>
                )}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <div className="h-5" />
      </form>
    </Form>
  )
}
