import { Link } from 'react-router-dom'
import { Tabs, TabsList, TabsTrigger } from './ui/tabs'

type Props = {
  value?: string
  tabs?: { value: string; to: string; title: string }[]
}

export function TabsLink({ value, tabs = [] }: Props) {
  const tabTriggerClass = [
    'border-b-2',
    'rounded-none',
    'bg-transparent',
    'data-[state=active]:border-purple',
    'data-[state=active]:shadow-none',
    'data-[state=active]:bg-transparent'
  ].join(' ')

  return (
    <Tabs className="mb-4" value={value}>
      <TabsList className="bg-transparent p-0 h-auto">
        {tabs.map((item) => {
          return (
            <TabsTrigger key={item.value} value={item.value} className={tabTriggerClass}>
              <Link to={item.to}>{item.title}</Link>
            </TabsTrigger>
          )
        })}
      </TabsList>
    </Tabs>
  )
}
