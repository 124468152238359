export const allowedFileTypeList = [
  'text/x-csrc', // .c
  'text/x-csharp', // .cs
  'text/x-c++src', // .cpp
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'text/html', // .html
  'text/x-java-source', // .java
  'application/json', // .json
  'text/markdown', // .md
  '.md',
  'application/pdf', // .pdf
  'text/x-php', // .php
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'text/x-python', // .py
  'text/x-ruby', // .rb
  'application/x-tex', // .tex
  'text/plain', // .txt
  'text/css', // .css
  'application/javascript', // .js
  'text/x-sh', // .sh
  'text/typescript', // .ts
  'text/csv', // .csv
  'image/jpeg', // .jpeg, .jpg
  'image/gif', // .gif
  'image/png', // .png
  'application/x-tar', // .tar
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/xml', // .xml
  'application/zip' // .zip
]

export const allowedPromptFileTypeList = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/epub+zip',
  'text/markdown',
  '.md',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain'
]
