import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { formatCurrency } from '@/lib/currency'
import { Coins, CreditCard, Gauge, Plus } from 'lucide-react'
import { ReactNode, useContext, useEffect, useState } from 'react'

import { createStripePaymentIntentFetcher, getApiBalanceFetcher } from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Spinner } from '@/components/ui/spinner'
import { useEnv } from '@/hooks/use-env'
import { Maybe } from '@/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { defaultTo, isNil } from 'ramda'
import { useForm } from 'react-hook-form'
import { Route, Routes, useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { z } from 'zod'

type UseStripeReturn = {
  data: Maybe<Stripe>
  isLoading: boolean
  error: Maybe<Error>
}

function useStripeLoader(): UseStripeReturn {
  const env = useEnv()
  const [data, setData] = useState<Maybe<Stripe>>(null)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<Maybe<Error>>(null)

  useEffect(() => {
    load()
  }, [])

  async function load(): Promise<void> {
    try {
      setLoading(true)
      if (isNil(env.APP_STRIPE_PUBLISHABLE_KEY)) {
        throw new Error('APP_STRIPE_PUBLISHABLE_KEY is not set')
      }
      const data = await loadStripe(env.APP_STRIPE_PUBLISHABLE_KEY)
      setData(data)
      setError(null)
    } catch (e) {
      setError(e as Error)
      captureException(e)
    } finally {
      setLoading(false)
    }
  }

  return { data, isLoading, error }
}

export function SettingsBalance() {
  const env = useEnv()
  const auth = useContext(AuthContext)
  const getBalanceApi = useSWR(
    [env.APP_API_BASE_URL, auth?.session?.access_token, '/api/balance'],
    getApiBalanceFetcher
  )

  const availableCredits = defaultTo(0, getBalanceApi.data?.available_balance)
  const currentUsage = defaultTo(0, getBalanceApi.data?.last_30_days_usage)

  const { data: stripe } = useStripeLoader()

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <Card>
        <CardHeader className="flex justify-between items-center flex-row p-4 space-y-0">
          <CardTitle className="text-lg font-medium flex gap-2 items-center text-muted-foreground">
            <Coins /> Available Credits
          </CardTitle>

          <AddBalanceDialog stripe={stripe}>
            <Button size="sm" className="flex items-center gap-2">
              <Plus size={16} />
              <span className="hidden sm:inline">Buy Credits</span>
            </Button>
          </AddBalanceDialog>
        </CardHeader>

        <CardContent className="p-4">
          <div className="font-medium text-xl">{formatCurrency(availableCredits * 100)}</div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex justify-between items-center flex-row p-4 space-y-0">
          <CardTitle className="text-lg font-medium flex gap-2 items-center text-muted-foreground">
            <Gauge /> Current Usage
          </CardTitle>
        </CardHeader>

        <CardContent className="p-4">
          <div className="font-medium text-xl">{formatCurrency(currentUsage * 100)}</div>
        </CardContent>
      </Card>

      <Routes>
        <Route path="completion" element={<Completion stripe={stripe} />} />
      </Routes>
    </div>
  )
}

type AddBalanceDialogProps = {
  children: ReactNode
  stripe: Maybe<Stripe>
}

enum CreditAmountSteps {
  Fifty = '50',
  Hundred = '100',
  TwoHundred = '200',
  FiveHundred = '500',
  Thousand = '1000'
}

const amountFormSchema = z.object({
  amount: z.nativeEnum(CreditAmountSteps)
})

type AmountFormValue = z.infer<typeof amountFormSchema>

function AddBalanceDialog({ children, stripe }: AddBalanceDialogProps) {
  const env = useEnv()
  const auth = useContext(AuthContext)
  const fetcherKey = [env.APP_API_BASE_URL, auth?.session?.access_token, '/api/stripe/create-payment-intent']
  const createPaymentIntentApi = useSWRMutation(fetcherKey, createStripePaymentIntentFetcher)
  const clientSecret: Maybe<string> = createPaymentIntentApi.data?.client_secret
  const amountForm = useForm<AmountFormValue>({
    resolver: zodResolver(amountFormSchema),
    defaultValues: {
      amount: CreditAmountSteps.Fifty
    }
  })
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState<1 | 2>(1)

  async function handleAmountFormSubmit(value: AmountFormValue) {
    await createPaymentIntentApi.trigger({
      amount: Number(value.amount)
    })
    setStep(2)
  }

  function handleBack() {
    setStep(1)
  }

  function handleDialogOpenChange(value: boolean) {
    setOpen(value)
    if (!value) {
      setStep(1)
    }
  }

  return (
    <Dialog open={open} onOpenChange={handleDialogOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContent className="p-4 overflow-y-auto max-h-[90vh]">
        <DialogHeader className="flex justify-between items-center flex-row p-4 space-y-0">
          <DialogTitle className="text-lg font-medium flex gap-2 items-center text-muted-foreground">
            <CreditCard /> Add Credits
          </DialogTitle>
          <DialogDescription />
        </DialogHeader>

        {step === 1 && (
          <>
            <Form {...amountForm}>
              <form onSubmit={amountForm.handleSubmit(handleAmountFormSubmit)}>
                <FormField
                  control={amountForm.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>Amount</FormLabel>
                      <FormControl className="pl-5 text-lg">
                        <Select value={String(field.value)} onValueChange={field.onChange}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select amount" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {Object.entries(CreditAmountSteps).map(([key, value]) => {
                                return (
                                  <SelectItem key={key} value={String(value)}>
                                    {formatCurrency(Number(value) * 100)}
                                  </SelectItem>
                                )
                              })}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="flex justify-end gap-2">
                  <Button type="button" variant="outline" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button className="min-w-20" type="submit" disabled={createPaymentIntentApi.isMutating}>
                    {createPaymentIntentApi.isMutating ? <Spinner /> : 'Next'}
                  </Button>
                </div>
              </form>
            </Form>
          </>
        )}

        {step === 2 && clientSecret && stripe && (
          <div className="flex flex-col gap-4">
            <div className="text-sm text-muted-foreground">
              You are about to add{' '}
              <span className="text-lg font-semibold">
                {formatCurrency(Number(amountForm.getValues('amount')) * 100)}
              </span>{' '}
              to your balance.
            </div>

            <Elements stripe={stripe} options={{ clientSecret }}>
              <CheckoutForm onBackClick={handleBack} />
            </Elements>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

type CheckoutFormProps = {
  onBackClick: () => void
}

export default function CheckoutForm({ onBackClick }: CheckoutFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState<Maybe<string>>(null)
  const [isLoading, setIsLoading] = useState(false)
  const auth = useContext(AuthContext)

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/settings/balance/completion`
      }
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('An unexpected error occured.')
    }

    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        // Access the email value like so:
        // onChange={(event) => {
        //  setEmail(event.value.email);
        // }}
        //
        // Prefill the email field like so:
        // options={{defaultValues: {email: 'foo@bar.com'}}}
      />

      <PaymentElement
        className="mb-4"
        id="payment-element"
        options={{
          defaultValues: {
            billingDetails: {
              email: auth?.session?.user.email
            }
          }
        }}
      />

      <div className="flex justify-end gap-2">
        <Button type="button" variant="outline" onClick={onBackClick}>
          Back
        </Button>
        <Button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">{isLoading ? <Spinner /> : 'Pay now'}</span>
        </Button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

type CompletionProps = {
  stripe: Maybe<Stripe>
}

function Completion({ stripe }: CompletionProps) {
  const navigate = useNavigate()

  const [messageBody, setMessageBody] = useState<ReactNode>('')
  const [open, setOpen] = useState(true)

  useEffect(() => {
    if (!stripe) return

    const url = new URL(window.location as any)
    const clientSecret = url.searchParams.get('payment_intent_client_secret')

    if (!clientSecret) return
    stripe.retrievePaymentIntent(clientSecret).then(({ error, paymentIntent }) => {
      setMessageBody(
        error
          ? error.message
          : `Payment succeeded! Your balance has increased with ${formatCurrency(paymentIntent.amount)}`
      )
    })
  }, [stripe])

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setOpen(value)
        if (!value) {
          navigate('/settings/balance')
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Thank you!</DialogTitle>
          <DialogDescription />
        </DialogHeader>

        <div id="messages" role="alert" style={messageBody ? { display: 'block' } : {}}>
          {messageBody}
        </div>
      </DialogContent>
    </Dialog>
  )
}
