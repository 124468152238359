import { Agent } from '@/types'

export enum AgentValue {
  WebCrawler = Agent.CrawlerAgent,
  SecFilings = Agent.SECFilingsAgent,
  EarningsCallTranscripts = Agent.TranscriptsAgent,
  PrivateMarket = Agent.PrivateMarketAgent
}

export enum OutputValue {
  PythonScript = 'Python Script',
  Analytics = 'Analytics',
  CSV = 'CSV',
  JSON = 'JSON'
}

export enum FilingType {
  TenK = '10-K',
  TenQ = '10-Q',
  EightK = '8-K',
  SOne = 'S-1',
  TwentyF = '20-F',
  FortyF = '40-F'
}
