import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { FormEvent } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'

enum AnalysisType {
  Tearsheet = 'tearsheet'
}

export const privateMarketSchema = z.object({
  companyName: z.string().min(1, { message: 'Company name is required' }),
  analysisType: z.nativeEnum(AnalysisType, {
    errorMap: () => ({ message: 'Analysis type is required' })
  })
})

export type PrivateMarketFormValue = z.infer<typeof privateMarketSchema>

type Props = {
  form: UseFormReturn<PrivateMarketFormValue>
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
}

export function PromptBuilderPrivateMarketForm({ form, onSubmit }: Props) {
  return (
    <Form key="prompt-builder-private-market-form" {...form}>
      <form key="prompt-builder-private-market-form" onSubmit={onSubmit}>
        <FormField
          control={form.control}
          name="analysisType"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold text-xs">Analysis type *</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger data-testid="prompt-builder-private-market-analysis-type-select">
                    <SelectValue placeholder="Select analysis type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value={AnalysisType.Tearsheet}>Create a Company Tearsheet</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="h-5" />

        <FormField
          control={form.control}
          name="companyName"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold text-xs">Company name *</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter company name"
                  {...field}
                  data-testid="prompt-builder-private-market-company-name-input"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="h-5" />
      </form>
    </Form>
  )
}
