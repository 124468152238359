import { createAgentSuggestion } from '@/lib/tiptap/agent-suggestion'
import { MentionTicker } from '@/lib/tiptap/ticker-mentions'
import { createTickerSuggestion } from '@/lib/tiptap/ticker-suggestion'
import { Bold } from '@tiptap/extension-bold'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor, EditorEvents, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

type UsePromptEditorOptions = {
  onUpdate: (props: EditorEvents['update']) => void
  onSubmit: () => void
}

type UsePromptEditorReturn = Editor | null

export function usePromptEditor({ onUpdate, onSubmit }: UsePromptEditorOptions): UsePromptEditorReturn {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Bold.configure({
        HTMLAttributes: {
          class: 'font-medium'
        }
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention font-medium'
        },
        suggestion: createAgentSuggestion(),
        renderText: ({ node }) => `@${node.attrs.id}`
      }),
      MentionTicker.configure({
        HTMLAttributes: {
          class: 'mention-ticker'
        },
        suggestion: createTickerSuggestion()
      }),
      Placeholder.configure({
        placeholder: 'Enter Research Request…'
      })
    ],
    editorProps: {
      attributes: {
        class: 'w-full pr-10 border-none max-h-32 h-fit min-h-10 outline-none overflow-y-auto p-2'
      },
      handleKeyDown: (_, event: KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          onSubmit()
          return true // Indicate that the event is handled
        }
        return false // Indicate that the event should be handled by the editor
      }
    },
    content: `<p></p>`,
    onUpdate
  })
  return editor
}
