import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { FileItem, useFileInput } from '@/hooks/use-file-input'
import { usePromptEditor } from '@/hooks/use-prompt-editor'
import { noop } from '@/lib/function'
import { isEnterHotkey } from '@/lib/hotkey'
import { Agent } from '@/types'
import { EditorContent, EditorEvents } from '@tiptap/react'
import { SendHorizonal } from 'lucide-react'
import { isNil, isNotNil, map, prop } from 'ramda'
import { KeyboardEventHandler, useEffect } from 'react'
import { PromptEditorAttachments } from './prompt-editor-attachments'
import { PromptEditorToolbar } from './prompt-editor-toolbar'

type PromptEditorProps = {
  value?: string
  onUpdate?(value: EditorEvents['update']): void
  onSubmit?(text: string, files: File[]): void
}

export function PromptEditor({ value, onUpdate = noop, onSubmit }: PromptEditorProps) {
  const { handleFileInput, fileList, removeFile, clearFiles } = useFileInput()
  const editor = usePromptEditor({ onUpdate, onSubmit: handleSubmit })

  useEffect(() => {
    editor?.commands.setContent(`<p>${value ?? ''}</p>`)
  }, [value])

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (isEnterHotkey(e)) {
      e.preventDefault()
      handleSubmit()
    }
  }

  function handleRemoveFileClick(item: FileItem) {
    removeFile(item)
  }

  function handleSubmit() {
    if (onSubmit && isNotNil(editor)) {
      onSubmit(editor.getText(), map(prop('file'), fileList))
      editor?.commands.clearContent()
      clearFiles()
    }
  }

  function handleAgentClick(item: Agent) {
    if (isNil(editor)) {
      return
    }
    editor
      .chain()
      .focus()
      .insertContent({
        type: 'mention',
        attrs: { id: item },
        text: `@${item}`
      })
      .insertContent(' ')
      .run()
  }

  return (
    <Card>
      <CardContent className="p-1 pb-1">
        <div className="relative">
          <EditorContent
            data-testid="input-prompt"
            onKeyDown={handleKeyDown}
            editor={editor}
            style={{ outline: 'none' }}
          />

          <Button
            data-testid="btn-submit-prompt"
            variant="ghost"
            className="absolute right-0 top-0 hover:bg-transparent"
            style={{ marginTop: 0 }}
            onClick={() => handleSubmit()}
          >
            <SendHorizonal size="16" />
          </Button>
        </div>

        <PromptEditorAttachments fileList={fileList} onRemove={handleRemoveFileClick} />

        <PromptEditorToolbar onAgentClick={handleAgentClick} onFileInput={handleFileInput} />
      </CardContent>
    </Card>
  )
}
