import { Agent } from '@/types'
import { complement, isNil } from 'ramda'
import colors from 'tailwindcss/colors'

const colorList = Object.values(colors)
  .filter((item: string | Record<string, string>): item is Record<string, string> => {
    return typeof item === 'object'
  })
  .map((item) => item['500'])
  .filter(complement(isNil))
  .sort()

const color_index_min = 0
const color_index_max = colorList.length - 1

const agentNameNumberMap: Record<string, number> = Object.values(Agent).reduce((acc, item) => {
  return {
    ...acc,
    [item]: item
      .split('')
      .map((item) => item.charCodeAt(0))
      .reduce((acc, curr) => acc + curr, 0)
  }
}, {})

const agent_name_number_min = Math.min(...Object.values(agentNameNumberMap))
const agent_name_number_max = Math.max(...Object.values(agentNameNumberMap))

export function getAgentColor(agent: string): string {
  const x: number = agentNameNumberMap[agent]
  const xRate = (agent_name_number_max - agent_name_number_min) / (x - agent_name_number_min)
  const colorIndex = Math.round((color_index_max - color_index_min) / xRate)
  const color = colorList[colorIndex]
  return color
}
