import { PageLayout } from '@/components/page-layout'
import { TabsLink } from '@/components/tabs-link'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { SettingsBalance } from './settings-balance'
import { SettingsKeys } from './settings-keys'
import { SettingsProfile } from './settings-profile'

export function SettingsPage() {
  const params = useParams()

  const tabValue = params['*']

  return (
    <PageLayout>
      <div>
        <TabsLink
          value={tabValue}
          tabs={[
            { value: 'profile', to: '/settings/profile', title: 'Profile' },
            { value: 'keys', to: '/settings/keys', title: 'API Keys' },
            { value: 'balance', to: '/settings/balance', title: 'Balance Usage' }
          ]}
        />

        <Routes>
          <Route path="/*" element={<Navigate to={{ pathname: '/settings/profile' }} />} />
          <Route path="profile/*" element={<SettingsProfile />} />
          <Route path="keys" element={<SettingsKeys />} />
          <Route path="balance" element={<SettingsBalance />} />
          <Route path="balance/*" element={<SettingsBalance />} />
        </Routes>
      </div>
    </PageLayout>
  )
}
