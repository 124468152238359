import { PromptFunctionsContext } from '@/contexts/prompt'
import { Maybe, Project } from '@/types'
import { EditorEvents } from '@tiptap/react'
import Cookies from 'js-cookie'
import { useContext, useEffect, useState } from 'react'
import { PromptEditor } from './prompt-editor'

type Props = {
  overrideValue?: string
  project?: Maybe<Project>
  onSubmit(value: { prompt: string; files: File[] }): void
}

export function PromptInput({ project, overrideValue, onSubmit }: Props) {
  const [value, setValue] = useState(overrideValue)
  const promptFunctions = useContext(PromptFunctionsContext)

  function handleUpdate({ editor }: EditorEvents['update']) {
    promptFunctions(project?.id, editor.getText())
  }

  function handleSubmit(text: string, files: File[]) {
    onSubmit({ prompt: text, files })
  }

  useEffect(() => {
    const ctaPrompt = Cookies.get('ctaPrompt')
    if (ctaPrompt) {
      setValue(() => `<span>${ctaPrompt}</span>`)
      promptFunctions(project?.id, ctaPrompt)
      Cookies.remove('ctaPrompt')
    } else if (overrideValue) {
      setValue(() => `<span>${overrideValue}</span>`)
      promptFunctions(project?.id, overrideValue)
    }
  }, [overrideValue, project?.id, promptFunctions])

  return <PromptEditor value={value} onUpdate={handleUpdate} onSubmit={handleSubmit} />
}
