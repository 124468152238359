import { useToast } from '@/components/ui/use-toast'
import { filter, map, prop, uniq } from 'ramda'
import { useState } from 'react'
import { v4 } from 'uuid'
import { allowedPromptFileTypeList } from '@/features/chat/utils'

export type FileItem = {
  file: File
  id: string
}

export function useFileInput() {
  const [fileList, setFileList] = useState<FileItem[]>([])

  const toast = useToast()

  function handleFileInput(e: React.FormEvent<HTMLInputElement>) {
    const { files } = e.target as HTMLInputElement
    if (!files) {
      return
    }
    addFiles(files)
  }

  function addFiles(files: FileList) {
    setFileList((prev) => {
      const unsupportedFileTypeList = uniq(
        filter((item: string) => !allowedPromptFileTypeList.includes(item), map(prop('type'), Array.from(files)))
      )
      const supportedFiles = filter((item) => allowedPromptFileTypeList.includes(item.type), Array.from(files))
      const supportedFileItems: FileItem[] = map((item) => ({ id: v4(), file: item }), supportedFiles)
      const nextList: FileItem[] = [...prev, ...supportedFileItems]

      if (unsupportedFileTypeList.length > 0) {
        toast.toast({
          description: `Files with following extentions not supported: ${unsupportedFileTypeList.join(' ,')}. `
        })
      }

      return nextList
    })
  }

  function removeFile(item: FileItem) {
    setFileList((prev) => prev.filter((file) => file.id !== item.id))
  }

  function clearFiles() {
    setFileList(() => [])
  }

  return {
    handleFileInput,
    removeFile,
    clearFiles,
    fileList
  }
}
