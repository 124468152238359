import {
  deleteProjectFetcher,
  duplicateProjectFetcher,
  getProjectListFetcher,
  updateProjectFetcher
} from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { PageLayout } from '@/components/page-layout'
import { PageLoader } from '@/components/page-loader'
import { ProjectFilter } from '@/components/project-filter'
import { ProjectTable } from '@/components/project-table'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { useEnv } from '@/hooks/use-env'
import { mapApiProjectToProject, mapProjectKeyToApiKey } from '@/lib/domain/project'
import { routePath } from '@/router/route-path'
import {
  Maybe,
  PaginatedList,
  Project,
  ProjectApiKey,
  ProjectFilterValue,
  ProjectListFilter,
  ProjectListSorting
} from '@/types'
import { RowSelectionState, Updater } from '@tanstack/react-table'
import { File } from 'lucide-react'
import { always, equals, flatten, isEmpty, isNil, map, omit } from 'ramda'
import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { match } from 'ts-pattern'

type ViewMode = 'default' | 'search'

export function ProjectsPage() {
  const [sorting, setSorting] = useState<Maybe<ProjectListSorting>>({
    sortBy: 'updatedAt',
    sortOrder: 'desc'
  })
  const [searching, setSearching] = useState<Maybe<ProjectListFilter>>()
  const [page, setPage] = useState(1)
  const env = useEnv()
  const auth = useContext(AuthContext)

  const PROJECT_API_KEY: ProjectApiKey = [
    env.APP_API_BASE_URL,
    mapProjectKeyToApiKey(sorting?.sortBy),
    sorting?.sortOrder,
    page,
    searching,
    auth?.session?.access_token
  ]
  const apiGetProjectList = useSWR(PROJECT_API_KEY, getProjectListFetcher, {
    revalidateOnReconnect: true,
    revalidateOnFocus: false,
    revalidateIfStale: true
  })

  const apiDeleteProject = useSWRMutation(PROJECT_API_KEY, deleteProjectFetcher)
  const apiDuplicateProject = useSWRMutation(PROJECT_API_KEY, duplicateProjectFetcher)
  const apiUpdateProject = useSWRMutation(PROJECT_API_KEY, updateProjectFetcher)

  const { toast } = useToast()
  const navigate = useNavigate()
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const projectListPaginated: PaginatedList<Project> = [
    [page, map(mapApiProjectToProject, apiGetProjectList.data ?? [])]
  ]

  const mode: ViewMode = match<[Maybe<string>, PaginatedList<Project>], ViewMode>([
    searching?.search,
    projectListPaginated
  ])
    .when(([a, b]) => !isEmpty(a) && !isEmpty(b), always('search'))
    .otherwise(always('default'))

  const projectList: Project[] = flatten(map(([_, itemList]) => itemList, projectListPaginated))

  const loadingMore = apiGetProjectList.isLoading

  function handleLoadMore() {
    // TODO: currently supports 1 page list
    // setPage(() => inc(page))
  }

  function handleSortingChange(value: ProjectListSorting) {
    setSorting(() => value)
    setPage(() => 1)
  }

  function handleProjectFilterChange(value: ProjectFilterValue) {
    const guardedName: string = value.name ?? ''
    let nextSearch = isEmpty(guardedName) ? null : { search: guardedName }
    setPage(() => 1)
    setSearching(() => nextSearch)
  }

  async function handleDelete(value: Project) {
    try {
      if (!env.APP_API_BASE_URL) {
        throw new Error('Failed to delete project. Application misconfigured.')
      }

      await apiDeleteProject.trigger({
        baseUrl: env.APP_API_BASE_URL,
        projectIdList: [value.id]
      })

      toast({ description: 'Project was successfully deleted.' })
    } catch (e) {
      const error = e as Error
      toast({ variant: 'destructive', description: error.message })
    }
  }

  async function handleDuplicate(item: Project) {
    try {
      if (!env.APP_API_BASE_URL) {
        throw new Error('Failed to duplicate project. Application misconfigured.')
      }
      const result = await apiDuplicateProject.trigger({
        baseUrl: env.APP_API_BASE_URL,
        projectId: item.id
      })

      toast({
        description: (
          <div>
            Project was successfully duplicated.
            <Button variant="secondary" onClick={() => handleViewProject(result.id)}>
              View Project
            </Button>
          </div>
        )
      })
    } catch (e) {
      const error = e as Error
      toast({ variant: 'destructive', description: error.message })
    }
  }

  async function handleUpdate(item: Project) {
    try {
      if (!env.APP_API_BASE_URL) {
        throw new Error('Failed to update project. Application misconfigured.')
      }
      await apiUpdateProject.trigger({ baseUrl: env.APP_API_BASE_URL, project: item })

      toast({
        description: (
          <div>
            <div className="mb-2">Project was successfully updated.</div>
            <Button variant="secondary" onClick={() => handleViewProject(item.id)}>
              View Project
            </Button>
          </div>
        )
      })

      setPage(always(1))
    } catch (e) {
      const error = e as Error
      toast({ variant: 'destructive', description: error.message })
    }
  }

  function handleViewProject(id: string) {
    navigate({ pathname: `${routePath.project}/${id}/chat` })
  }

  async function handleBulkDelete(itemList: Project[]) {
    try {
      if (!env.APP_API_BASE_URL) {
        throw new Error('Failed to delete selected projects. Application misconfigured.')
      }
      const projectIdList = map((item) => item.id, itemList)
      await apiDeleteProject.trigger({
        baseUrl: env.APP_API_BASE_URL,
        projectIdList
      })
      toast({ description: 'Selected projects were successfully deleted.' })
      setRowSelection((prev) => {
        const next: RowSelectionState = omit(projectIdList, prev)
        return next
      })
    } catch (e) {
      const error = e as Error
      toast({ variant: 'destructive', description: error.message })
    }
  }

  function handleRowSelectionChange(valueUpdater: Updater<RowSelectionState>) {
    setRowSelection((prev) => {
      const next = typeof valueUpdater === 'function' ? valueUpdater(prev) : prev
      return next
    })
  }

  if (isEmpty(projectList) && isNil(apiGetProjectList.data)) {
    return <PageLoader />
  }

  return (
    <PageLayout>
      <div className="flex flex-col gap-[10px] h-full">
        <h3 className="h-[40px] flex items-center justify-between">
          <span className="font-bold">Projects</span>
          <ProjectFilter
            className="md:w-[50%]"
            onChange={handleProjectFilterChange}
            onSortingChange={handleSortingChange}
          />
        </h3>

        {match([projectList, mode])
          .when(
            ([a, b]) => isEmpty(a) && equals(b, 'default'),
            () => (
              <div className="flex flex-col justify-center items-center gap-5 grow bg-white dark:bg-secondary rounded-lg">
                <File size={44} />
                <p className="w-[438px] text-primary text-sm text-center">
                  Octagon will assist you in creating a variety of projects, including Quarterly Earnings Result Notes,
                  Informational Memorandums, Monthly Market Updates, and Comp Tables.
                </p>
                <Button asChild>
                  <Link to={routePath.createProject}>Start a project</Link>
                </Button>
              </div>
            )
          )
          .when(
            ([a, b]) => isEmpty(a) && equals(b, 'search'),
            () => (
              <div className="flex flex-col justify-center items-center gap-5 grow bg-white dark:bg-secondary rounded-lg">
                <File size={44} />
                <p className="w-[438px] text-primary text-sm text-center">
                  No projects found.{' '}
                  <Link to={routePath.createProject} style={{ color: 'blue' }}>
                    Start New Project
                  </Link>
                </p>
              </div>
            )
          )
          .otherwise(() => (
            <div className="flex flex-col gap-4 dark:bg-secondary rounded-lg flex-grow">
              <ProjectTable
                projectList={projectList}
                loadingMore={loadingMore}
                sorting={sorting}
                rowSelection={rowSelection}
                onDelete={handleDelete}
                onDublicate={handleDuplicate}
                onUpdate={handleUpdate}
                onLoadMore={handleLoadMore}
                onSortingChange={handleSortingChange}
                onBulkDelete={handleBulkDelete}
                onRowSelectionChange={handleRowSelectionChange}
              />
            </div>
          ))}
      </div>
    </PageLayout>
  )
}
