import { routePath } from '@/router/route-path'
import { useParams } from 'react-router-dom'
import { TabsLink } from './tabs-link'

export function ChatTabs() {
  const routeParams = useParams()

  return (
    <TabsLink
      value={routeParams['*']}
      tabs={[
        { value: 'chat', to: `${routePath.project}/${routeParams.projectId}${routePath.chat}`, title: 'Research' },
        { value: 'sources', to: `${routePath.project}/${routeParams.projectId}${routePath.sources}`, title: 'Sources' }
      ]}
    />
  )
}
